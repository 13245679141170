<template>
    <nav class="app-sidebar-inner">

        <div class="sidebar-main">

            <popper
                trigger="click"
                :options="{
                    placement: 'right-start',
                    modifiers: { 
                        offset: { offset: '0,10px' }, 
                        preventOverflow: {escapeWithReference: true} 
                    }
                }"
                :append-to-body="true">

                <div class="popper menu product-list">
                    <div class="popper-inner">
                        <div class="popper-menu">
                            <div class="popper-menu-products" v-if="product_list.length > 0">
                                <div class="popper-menu-item" v-for="(product_item, key) in Object.values(product_list).filter(product_list_item => !product_list_item.organization_id || product_list_item.organization_id == 0 || product_list_item.organization_id !== product_list_item.ID)" v-bind:key="key">
                                    <a href="#_" v-on:click="switch_product(product_item.ID)">
                                    <div class="popper-menu-inner">
                                        <div class="popper-menu-icon">
                                            <img class="circle" :src="product_item.product_img">
                                        </div>
                                        <div class="popper-menu-text"> {{product_item.product_name}} <span class="current" v-if="product_item.ID == product.ID"></span> </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                            <div v-else>
                                <loader type="dark" height="50"/>
                            </div>
                            <div class="popper-menu-devider"></div>
                            <div class="popper-menu-item">
                                <router-link to="/add" v-if="organization_id == false">
                                <div class="popper-menu-inner">
                                    <div class="popper-menu-icon">
                                        <ion-icon name="ios-add"></ion-icon>
                                    </div>
                                    <div class="popper-menu-text"> {{ $t("sidebar.add_product") }} </div>
                                </div>
                                </router-link>
                                <a v-else v-on:click="switch_product(organization_id, '/')">
                                    <div class="popper-menu-inner">
                                    <div class="popper-menu-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 512 512"><title>Business</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M176 416v64M80 32h192a32 32 0 0132 32v412a4 4 0 01-4 4H48h0V64a32 32 0 0132-32zM320 192h112a32 32 0 0132 32v256h0-160 0V208a16 16 0 0116-16z"/><path d="M98.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79z"/><ellipse cx="256" cy="176" rx="15.95" ry="16.03" transform="rotate(-45 255.99 175.996)"/><path d="M258.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM400 400a16 16 0 1016 16 16 16 0 00-16-16zM400 320a16 16 0 1016 16 16 16 0 00-16-16zM400 240a16 16 0 1016 16 16 16 0 00-16-16zM336 400a16 16 0 1016 16 16 16 0 00-16-16zM336 320a16 16 0 1016 16 16 16 0 00-16-16zM336 240a16 16 0 1016 16 16 16 0 00-16-16z"/></svg>
                                    </div>
                                    <div class="popper-menu-text popper-menu-organization"> <b>Organization</b> </div>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sidebar-product" slot="reference" @click="get_products">
                    <div>
                        <div class="logo">
                            <img :src="product.product_img" v-if="organization == false">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN88uLVfwAJJQO3dhRNKgAAAABJRU5ErkJggg==" v-else>
                        </div>
                        <div class="name">
                            {{ organization == false ? product.product_name : 'Select...' }}
                        </div>
                        <div class="more">
                            <ion-icon name="ios-add"></ion-icon>
                        </div>
                    </div>
                </div>
            </popper>

            <div class="mobile-menu">
                <div class="hamburger hamburger--spin" :class="{'active': menu_open}" type="button" v-on:click="menu_open = !menu_open">
                    <div class="hamburger-box">
                        <div class="hamburger-inner" ></div>
                    </div>
                </div>
            </div>

        </div>

        <div class="sidebar-nav" :class="{'open': menu_open}">

            <!-- Default Menu -->
            <ul class="menu" v-if="organization == false">
                <li>
                    <div>
                        <router-link to="/" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Timer</title><path d='M112.91 128A191.85 191.85 0 0064 254c-1.18 106.35 85.65 193.8 192 194 106.2.2 192-85.83 192-192 0-104.54-83.55-189.61-187.5-192a4.36 4.36 0 00-4.5 4.37V152' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/><path d='M233.38 278.63l-79-113a8.13 8.13 0 0111.32-11.32l113 79a32.5 32.5 0 01-37.25 53.26 33.21 33.21 0 01-8.07-7.94z' fill="currentColor"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.dashboard") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li class="space"></li>
                <li v-if="product_settings.general.module_feedback == true" :class="{ 'active': $route.path.startsWith('/feedback') }">
                    <div>
                        <router-link to="/feedback" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Chatbubbles</title><path d='M431 320.6c-1-3.6 1.2-8.6 3.3-12.2a33.68 33.68 0 012.1-3.1A162 162 0 00464 215c.3-92.2-77.5-167-173.7-167-83.9 0-153.9 57.1-170.3 132.9a160.7 160.7 0 00-3.7 34.2c0 92.3 74.8 169.1 171 169.1 15.3 0 35.9-4.6 47.2-7.7s22.5-7.2 25.4-8.3a26.44 26.44 0 019.3-1.7 26 26 0 0110.1 2l56.7 20.1a13.52 13.52 0 003.9 1 8 8 0 008-8 12.85 12.85 0 00-.5-2.7z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32'/><path d='M66.46 232a146.23 146.23 0 006.39 152.67c2.31 3.49 3.61 6.19 3.21 8s-11.93 61.87-11.93 61.87a8 8 0 002.71 7.68A8.17 8.17 0 0072 464a7.26 7.26 0 002.91-.6l56.21-22a15.7 15.7 0 0112 .2c18.94 7.38 39.88 12 60.83 12A159.21 159.21 0 00284 432.11' fill='none' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32'/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.feedback") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li v-if="product_settings.general.module_roadmap == true">
                    <div>
                        <router-link to="/roadmap" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Map</title><path d='M313.27 124.64L198.73 51.36a32 32 0 00-29.28.35L56.51 127.49A16 16 0 0048 141.63v295.8a16 16 0 0023.49 14.14l97.82-63.79a32 32 0 0129.5-.24l111.86 73a32 32 0 0029.27-.11l115.43-75.94a16 16 0 008.63-14.2V74.57a16 16 0 00-23.49-14.14l-98 63.86a32 32 0 01-29.24.35zM328 128v336M184 48v336' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.roadmap") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li v-if="product_settings.general.module_changelog == true" :class="{ 'active': $route.path.startsWith('/changelog') }">
                    <div>
                        <router-link to="/changelog" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M407.94 52.22S321.3 160 240 160H80a16 16 0 00-16 16v96a16 16 0 0016 16h160c81.3 0 167.94 108.23 167.94 108.23 6.06 8 24.06 2.52 24.06-9.83V62c0-12.31-17-18.82-24.06-9.78zM64 256s-16-6-16-32 16-32 16-32M448 246s16-4.33 16-22-16-22-16-22M256 160v128M112 160v128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M144 288v168a8 8 0 008 8h53a16 16 0 0015.29-20.73C211.91 416.39 192 386.08 192 336h16a16 16 0 0016-16v-16a16 16 0 00-16-16h-16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.changelog") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li v-if="product_settings.general.moderation == true || product_settings.general.moderation_comments">
                    <div>
                        <router-link to="/moderation" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                   <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Shield Checkmark</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M336 176L225.2 304 176 255.8"/><path d="M463.1 112.37C373.68 96.33 336.71 84.45 256 48c-80.71 36.45-117.68 48.33-207.1 64.37C32.7 369.13 240.58 457.79 256 464c15.42-6.21 223.3-94.87 207.1-351.63z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.moderation") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li class="space" v-if="product_settings.general.module_satisfaction == true || product_settings.general.module_promoter == true"></li>
                <li v-if="product_settings.general.module_satisfaction == true">
                    <div>
                        <router-link to="/satisfaction" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon"> 
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><circle cx="184" cy="232" r="24" fill="currentColor"/><path d="M256.05 384c-45.42 0-83.62-29.53-95.71-69.83a8 8 0 017.82-10.17h175.69a8 8 0 017.82 10.17c-11.99 40.3-50.2 69.83-95.62 69.83z" fill="currentColor"/><circle cx="328" cy="232" r="24" fill="currentColor"/><circle cx="256" cy="256" r="208" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.satisfaction") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li v-if="product_settings.general.module_promoter == true" :class="{ 'active': $route.path.startsWith('/promoter') }">
                    <div>
                        <router-link to="/promoter" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.promoter") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li v-if="product_settings.general.module_survey == true" :class="{ 'active': $route.path.startsWith('/survey') }">
                    <div>
                        <router-link to="/survey" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/><rect x="64" y="64" width="384" height="384" rx="48" ry="48" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.survey") }} </div>
                                <div class="nav-badge"> <span>BETA</span> </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li class="space"></li>
                <li :class="{ 'active': $route.path.startsWith('/user') }">
                    <div>
                        <router-link to="/user" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>People</title><path d='M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/><path d='M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32'/><path d='M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/><path d='M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154' fill='none' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32'/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.user") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
            </ul>

            <!-- Organization Menu -->
            <ul class="menu" v-else>
                <li>
                    <div>
                        <router-link to="/" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Business</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M176 416v64M80 32h192a32 32 0 0132 32v412a4 4 0 01-4 4H48h0V64a32 32 0 0132-32zM320 192h112a32 32 0 0132 32v256h0-160 0V208a16 16 0 0116-16z"/><path d="M98.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79z"/><ellipse cx="256" cy="176" rx="15.95" ry="16.03" transform="rotate(-45 255.99 175.996)"/><path d="M258.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM400 400a16 16 0 1016 16 16 16 0 00-16-16zM400 320a16 16 0 1016 16 16 16 0 00-16-16zM400 240a16 16 0 1016 16 16 16 0 00-16-16zM336 400a16 16 0 1016 16 16 16 0 00-16-16zM336 320a16 16 0 1016 16 16 16 0 00-16-16zM336 240a16 16 0 1016 16 16 16 0 00-16-16z"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.organization") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li class="space"></li>
                <li :class="{ 'active': $route.path.startsWith('/organization/products') }">
                    <div>
                        <router-link to="/organization/products" class="nav-item" v-if="['owner', 'admin'].includes(product_user_role) == true">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Settings</title><path d='M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.organization_products") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li :class="{ 'active': $route.path.startsWith('/organization/team') }">
                    <div>
                        <router-link to="/organization/team" class="nav-item" v-if="['owner', 'admin'].includes(product_user_role) == true">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>People</title><path d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/><path d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.organization_team") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li :class="{ 'active': $route.path.startsWith('/organization/plan') }">
                    <div>
                        <router-link to="/organization/plan" class="nav-item" v-if="['owner'].includes(product_user_role) == true">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Card</title><rect x="48" y="96" width="416" height="320" rx="56" ry="56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="60" d="M48 192h416M128 300h48v20h-48z"/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.organization_plan") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
            </ul>

            <ul class="menu" style="margin-top: auto;">
                <li v-if="organization == false">
                    <div>
                        <span class="nav-item" v-on:click="load_shortcuts">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M315.27 33L96 304h128l-31.51 173.23a2.36 2.36 0 002.33 2.77h0a2.36 2.36 0 001.89-.95L416 208H288l31.66-173.25a2.45 2.45 0 00-2.44-2.75h0a2.42 2.42 0 00-1.95 1z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                                </div>
                                <div class="nav-text"> Shortcuts </div>
                                <div class="nav-badge"> <span>⌘</span> <span>K</span> </div>
                            </div>
                        </span>
                    </div>
                </li>
                <li v-if="organization == false" :class="{ 'active': $route.path.startsWith('/settings') }">
                    <div>
                        <router-link to="/settings" class="nav-item" v-if="['owner', 'admin'].includes(product_user_role) == true">
                            <div class="nav-item-inner">
                                <div class="nav-icon">
                                    <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Settings</title><path d='M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/></svg>
                                </div>
                                <div class="nav-text"> {{ $t("sidebar.settings") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <span class="nav-item">
                            <popper
                                ref="popper_notification"
                                trigger="click"
                                :append-to-body="true"
                                :options="{
                                    placement: 'top-start',
                                    keepTogether: { enabled: false },
                                    modifiers: {
                                        offset: {
                                            enabled: true,
                                            offset: '0,10px',
                                        },
                                        computeStyle: {
                                            gpuAcceleration: false,
                                        },
                                        preventOverflow: {
                                            enabled: false,
                                            padding: 0
                                        }
                                    },
                                }"
                                v-on:hide="notifications_reset">

                                <div class="popper user-notifications">
                                    <div class="popper-inner">

                                        <div class="user-notification-title">
                                            <div>
                                                <div class="info">Notifications</div>
                                                <div class="action" v-on:click="notifications_set_read('all')">Mark all as read</div>
                                            </div>
                                        </div>

                                        <div v-if="notification_items" class="user-notification-feed" v-on:scroll.passive="notifications_scroll_feed($event)">
                                            <div class="no-results" v-if="typeof notification_items === 'object' && notification_items.length == 0">
                                                <div>
                                                    <ion-icon name="close"></ion-icon>
                                                    No notifications...
                                                </div>
                                            </div>
                                            <div v-else>
                                                
                                                <div v-for="notification_item in notification_items" class="user-notification-item">
                                                    <router-link class="user-notification" v-if="notification_item.product_id == product.ID" v-on:click.native="notifications_set_read(notification_item.notifications_id); notifications_close();" :to="notifications_link( notification_item, false )" v-bind:class="{ read: notification_item.readit !== false, seen: notification_item.seenit !== false }">
                                                        <div class="user-notification-img" v-if="notification_item.triggered_img">
                                                            <img :src="notification_item.triggered_img">
                                                        </div>
                                                        <div class="user-notification-text">
                                                            <span v-if="notification_item.type == 'owner'"><strong>{{notification_item.triggered_name}}</strong> made you the owner of: <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'post'"><strong>{{notification_item.triggered_name}}</strong> submitted a feedback post: <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'comment'"><strong>{{notification_item.triggered_name}}</strong> wrote a comment on <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'mention'"><strong>{{notification_item.triggered_name}}</strong> mentioned you in a comment on <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'like'"><strong>{{notification_item.triggered_name}}</strong> liked your comment on <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'status'">The status of <strong>{{notification_item.feedback_title}}</strong> has changed to 
                                                                <span class="item-tag" v-if="notification_item.status && product_settings.status[notification_item.status]" :style="{background: product_settings.status[notification_item.status].color}">
                                                                        {{product_settings.status[notification_item.status].name}}
                                                                </span>
                                                            </span>
                                                            <span v-if="notification_item.type == 'changelog'">
                                                                <span class="item-tag" v-if="notification_item.changelog_type && product_settings.type[notification_item.changelog_type]" :style="{background: product_settings.type[notification_item.changelog_type].color}">
                                                                        {{product_settings.type[notification_item.changelog_type].name}}
                                                                </span>
                                                                The changelog has been updated: <strong>{{notification_item.changelog_title}}</strong> 
                                                            </span>
                                                            <div>
                                                                <span>{{notification_item.product_name}}</span> &#183; <time>{{ notification_item.created | moment }}</time>
                                                            </div>
                                                        </div>
                                                        <div class="user-notification-icon">
                                                            <ion-icon name="ios-flash" v-if="notification_item.type == 'owner'"></ion-icon>
                                                            <ion-icon name="ios-bulb" v-if="notification_item.type == 'post'"></ion-icon>
                                                            <ion-icon name="ios-chatbubbles" v-if="notification_item.type == 'comment'"></ion-icon>
                                                            <ion-icon name="ios-at" v-if="notification_item.type == 'mention'"></ion-icon>
                                                            <ion-icon name="ios-heart" v-if="notification_item.type == 'like'"></ion-icon>
                                                            <ion-icon name="eye" v-if="notification_item.type == 'status'"></ion-icon>
                                                            <ion-icon name="ios-rocket" v-if="notification_item.type == 'changelog'"></ion-icon>
                                                        </div>
                                                    </router-link>
                                                    <a v-else href="#_" class="user-notification" v-on:click="notifications_set_read(notification_item.notifications_id, () => { switch_product( notification_item.product_id, notifications_link( notification_item, true ) ); notifications_close(); });" v-bind:class="{ read: notification_item.readit !== false, seen: notification_item.seenit !== false }">
                                                        <div class="user-notification-img" v-if="notification_item.triggered_img">
                                                            <img :src="notification_item.triggered_img">
                                                        </div>
                                                        <div class="user-notification-text">
                                                            <span v-if="notification_item.type == 'owner'"><strong>{{notification_item.triggered_name}}</strong> made you the owner of: <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'post'"><strong>{{notification_item.triggered_name}}</strong> submitted a feedback post: <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'comment'"><strong>{{notification_item.triggered_name}}</strong> wrote a comment on <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'mention'"><strong>{{notification_item.triggered_name}}</strong> mentioned you in a comment on <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'like'"><strong>{{notification_item.triggered_name}}</strong> liked your comment on <strong>{{notification_item.feedback_title}}</strong></span>
                                                            <span v-if="notification_item.type == 'status'">The status of <strong>{{notification_item.feedback_title}}</strong> has changed to 
                                                                <span class="item-tag" v-if="notification_item.status && product_settings.status[notification_item.status]" :style="{background: product_settings.status[notification_item.status].color}">
                                                                        {{product_settings.status[notification_item.status].name}}
                                                                </span>
                                                            </span>
                                                            <span v-if="notification_item.type == 'changelog'">
                                                                <span class="item-tag" v-if="notification_item.changelog_type && product_settings.type[notification_item.changelog_type]" :style="{background: product_settings.type[notification_item.changelog_type].color}">
                                                                        {{product_settings.type[notification_item.changelog_type].name}}
                                                                </span>
                                                                The changelog has been updated: <strong>{{notification_item.changelog_title}}</strong> 
                                                            </span>
                                                            <div>
                                                                <span>{{notification_item.product_name}}</span> &#183; <time>{{ notification_item.created | moment }}</time>
                                                            </div>
                                                        </div>
                                                        <div class="user-notification-icon">
                                                            <ion-icon name="ios-flash" v-if="notification_item.type == 'owner'"></ion-icon>
                                                            <ion-icon name="ios-bulb" v-if="notification_item.type == 'post'"></ion-icon>
                                                            <ion-icon name="ios-chatbubbles" v-if="notification_item.type == 'comment'"></ion-icon>
                                                            <ion-icon name="ios-at" v-if="notification_item.type == 'mention'"></ion-icon>
                                                            <ion-icon name="ios-heart" v-if="notification_item.type == 'like'"></ion-icon>
                                                            <ion-icon name="eye" v-if="notification_item.type == 'status'"></ion-icon>
                                                            <ion-icon name="ios-rocket" v-if="notification_item.type == 'changelog'"></ion-icon>
                                                        </div>
                                                    </a>
                                                </div>

                                                <!-- on load more -->
                                                <div v-if="notifications_query && notifications_query.has_more == true">
                                                    <loader type="dark" height="40" size="small"/>
                                                </div>

                                            </div>
                                        </div>
                                        <!-- on load -->
                                        <div v-else class="user-notification-feed">
                                            <loader type="dark" height="300" size="large"/>
                                        </div>

                                    </div>
                                </div>
                                
                                <div class="nav-item-inner" slot="reference" v-on:click.prevent="notifications_load();">
                                    <div class="nav-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path d="M427.68 351.43C402 320 383.87 304 383.87 217.35 383.87 138 343.35 109.73 310 96c-4.43-1.82-8.6-6-9.95-10.55C294.2 65.54 277.8 48 256 48s-38.21 17.55-44 37.47c-1.35 4.6-5.52 8.71-9.95 10.53-33.39 13.75-73.87 41.92-73.87 121.35C128.13 304 110 320 84.32 351.43 73.68 364.45 83 384 101.61 384h308.88c18.51 0 27.77-19.61 17.19-32.57zM320 384v16a64 64 0 01-128 0v-16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                                    </div>
                                    <div class="nav-text"> Notifications </div>
                                    <div class="nav-badge" v-if="notifications_count > 0" :data-count="notifications_count">
                                        <span>{{ notifications_count }}</span>
                                    </div>
                                </div>
            
                            </popper>
                        </span>
                    </div>
                </li>
                <li :class="{ 'active': $route.path.startsWith('/account') }">
                    <div>
                        <span class="nav-item">
                            <popper
                                ref="popper_notification"
                                trigger="click"
                                :append-to-body="true"
                                :options="{
                                    placement: 'top-start',
                                    keepTogether: { enabled: false },
                                    modifiers: {
                                        offset: {
                                            enabled: true,
                                            offset: '0,10px',
                                        },
                                        computeStyle: {
                                            gpuAcceleration: false,
                                        },
                                        preventOverflow: {
                                            enabled: false,
                                            padding: 0
                                        }
                                    },
                                }">

                                <div class="popper menu">
                                    <div class="popper-inner">
                                        <div class="popper-menu">
                                            <div class="popper-menu-item">
                                                <router-link to="/help">
                                                <div class="popper-menu-inner">
                                                    <div class="popper-menu-icon">
                                                        <ion-icon name="ios-help-buoy"></ion-icon>
                                                    </div>
                                                    <div class="popper-menu-text"> {{ $t("sidebar.help") }} </div>
                                                </div>
                                                </router-link>
                                            </div>
                                            <div class="popper-menu-item">
                                                <router-link to="/account/views">
                                                <div class="popper-menu-inner">
                                                    <div class="popper-menu-icon">
                                                        <ion-icon name="ios-bookmark"></ion-icon>
                                                    </div>
                                                    <div class="popper-menu-text"> Saved searches </div>
                                                </div>
                                                </router-link>
                                            </div>
                                            <div class="popper-menu-item">
                                                <router-link to="/account">
                                                <div class="popper-menu-inner">
                                                    <div class="popper-menu-icon">
                                                        <ion-icon name="ios-settings"></ion-icon>
                                                    </div>
                                                    <div class="popper-menu-text"> {{ $t("sidebar.account") }} </div>
                                                </div>
                                                </router-link>
                                            </div>
                                            <div class="popper-menu-devider"></div>
                                            <div class="popper-menu-item">
                                                <a href="#_" v-on:click="logout">
                                                <div class="popper-menu-inner">
                                                    <div class="popper-menu-icon">
                                                        <ion-icon name="ios-log-out"></ion-icon>
                                                    </div>
                                                    <div class="popper-menu-text"> {{ $t("sidebar.logout") }} </div>
                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="nav-item-inner" slot="reference">
                                    <div class="nav-icon">
                                        <img :src="user.user_public_img">
                                    </div>
                                    <div class="nav-text"> {{user.user_name}} </div>
                                </div>
            
                            </popper>
                        </span>
                    </div>
                </li>
            </ul>

        </div>

        <div class="sidebar-nav extend">

            <ul class="menu settings" v-if="$route.path.startsWith('/settings')">
                <li :class="{ 'active': $route.path.startsWith('/settings/general') }">
                    <div>
                        <router-link to="/settings/general" class="nav-item" :class="{'nav-item-active': $route.path.startsWith('/settings/general')}">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_general") }} </div>
                                <div class="nav-more">
                                    <ion-icon class="open" name="ios-arrow-down"></ion-icon>
                                    <ion-icon class="close" name="ios-arrow-up"></ion-icon>
                                </div>
                            </div>
                        </router-link>
                        <div class="sub">
                            <ul class="menu">
                                <li>
                                    <div>
                                        <router-link to="/settings/general" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> General Settings </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <router-link to="/settings/general/team" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> Teammates </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <router-link to="/settings/general/legal" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> Legal & Privacy </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <router-link to="/settings/general/files" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> Uploads </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                                <li class="space text">Module Settings</li>
                                <li>
                                    <div>
                                        <router-link to="/settings/general/feedback" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> Feedback Board </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <router-link to="/settings/general/changelog" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> Changelog </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <router-link to="/settings/general/roadmap" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> Roadmap </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/widget/widget" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_widget") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/widget/page" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_page") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/popup" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_popup") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/privacy" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_privacy") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li :class="{ 'active': $route.path.startsWith('/settings/email') }">
                    <div>
                        <router-link to="/settings/email" class="nav-item" :class="{'nav-item-active': $route.path.startsWith('/settings/email')}">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_email") }} </div>
                                <div class="nav-more">
                                    <ion-icon class="open" name="ios-arrow-down"></ion-icon>
                                    <ion-icon class="close" name="ios-arrow-up"></ion-icon>
                                </div>
                            </div>
                        </router-link>
                        <div class="sub">
                            <ul class="menu">
                                <li>
                                    <div>
                                        <router-link to="/settings/email" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> Domain </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <router-link to="/settings/email/template" class="nav-item">
                                            <div class="nav-item-inner">
                                                <div class="nav-text"> Templates </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/status" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_status") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/type" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_type") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/tags" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_tags") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/segments" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_segments") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/developer" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_developer") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/integrations" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_integrations") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li>
                    <div>
                        <router-link to="/settings/import" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_import") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
                <li v-if="organization_id == false">
                    <div>
                        <router-link to="/settings/plan" class="nav-item">
                            <div class="nav-item-inner">
                                <div class="nav-text"> {{ $t("sidebar.settings_plan") }} </div>
                            </div>
                        </router-link>
                    </div>
                </li>
            </ul>

        </div>

        <div class="sidebar-collapse" v-on:click="sidebar_toggle">
            <div>
                <ion-icon class="collapse" name="ios-arrow-back"></ion-icon>
                <ion-icon class="expand" name="ios-arrow-forward"></ion-icon>
            </div>
        </div>

    </nav>
</template>

<script>
import Popper from 'vue-popperjs';
import moment from 'moment';
import DateHelper from '@/common/helper-date.js';

export default {

    name: 'sidebar',


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
        return {
            menu_open: false,
        };

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        user() {
            return this.$store.getters.user_data;
        },

        product() {
            return this.$store.getters.product_active;
        },

        product_settings() {
            return this.$store.getters.product_settings;
        },

        product_list() {
            return this.$store.getters.product_list;
        },

        product_user_role() {
            return this.$store.getters.product_role;
        },

        notification_items() {

            // set notifications items to false
            var notification_items = false;

            // check if we have notifications items
            if( this.$store.getters.user_notifications_items ) {
                
                // set helper variables
                notification_items  = this.$store.getters.user_notifications_items;

                // sort items by date
                notification_items = Object.values(notification_items).sort(function(a,b){
                    // Turn strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
                    return new Date(DateHelper.convert(b.created)) - new Date(DateHelper.convert(a.created));
                });

            }

            // return sorted notifications items
            return notification_items;
        },

        notifications_query() {
            return this.$store.getters.user_notifications_query;
        },

        notifications_count() {
            return this.$store.getters.user_notifications_count;
        },

        organization() {
            return ((!this.product.organization_id || parseInt(this.product.organization_id) == 0 || parseInt(this.product.organization_id) !== parseInt(this.product.ID)) ? false : true);
        },

        organization_id() {
            return ((this.product.organization_id && parseInt(this.product.organization_id) !== 0) ? parseInt(this.product.organization_id) : false);
        }

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        logout() {

            this.$store.dispatch( 'user_logout' )
            .then(() => this.$router.push({ name: "login" }));

        },

        get_products() {

            this.$store.dispatch( 'products_get' )
            .then(() => { 
                // trigger resize to fit the popper box
                window.dispatchEvent( new Event('resize') ) 
            });

        },

        switch_product( product_id, link = false ) {

            // if no link
            if( link == false ) {
                // set active product
                this.$store.dispatch( 'product_set', {product_id: product_id} )
                .then(() => {
                    // reload page
                    location.reload();
                });
            }

            // if with link
            if( link !== false ) {
                // set active product
                this.$store.dispatch( 'product_set_local', product_id )
                .then(() => {
                    // load link
                    location.href = link;
                });
            }

        },

        /**
         *  Load notifications
         */
        notifications_load( current_page = 0 ) {

            // load semaphore
            if( this.semaphore === true )
                return false;
            this.semaphore = true;

            // load feedback item
			this.$store.dispatch( 'user_notifications_query', {page: current_page} ).then(() => {
                // release semaphore
                this.semaphore = false;
            });

        },

        /**
         *  Load notifications count
         */
        notifications_load_count() {

            // load feedback item
			this.$store.dispatch( 'user_notifications_count' );

        },

        /**
         *  Update notifications count
         */
        notifications_update_count( message ) {

            // set new notification count
            var _new_notification_count = (this.notifications_count + message.count);

            // live notifications count
            this.$store.dispatch( 'notification_add', { name: 'notifications', type: 'system', message: 'You have ' + _new_notification_count + ' unread notifications', timeout: 14000 } );

            // load feedback item
            this.$store.commit('USER_NOTIFICATIONS_SET_COUNT', _new_notification_count); 

        },

        /**
         *  Close notifications
         */
        notifications_close() {

            // close popper
            this.$refs.popper_notification.doClose();

        },

        /**
         *  Close notifications
         */
        notifications_reset() {

            // reset notification read status
			this.$store.dispatch( 'user_notifications_reset' );

        },

        /**
         *  Notifications on scroll event
         */
        notifications_scroll_feed( e ) {
                
            // check if we have more items
            if( this.notifications_query.has_more !== false ) {
                // check if bottom reached
                let bottomOfWindow = e.target.scrollTop === (e.target.scrollHeight - e.target.offsetHeight);
                // if bottom reached
                if (bottomOfWindow) {
                    // load more
                    this.notifications_load( this.notifications_query.page + 1 );
                }
            }

        },

        /**
         *  Set notifications read status
         */
        notifications_set_read( notification_id, done = () => {} ) {

            // set notification read status
			this.$store.dispatch( 'user_notifications_read', notification_id ).then(() => { 
                done();
            });

        },

        /**
         *  Get notification link
         */
        notifications_link( notification_item, href = false ) {

            // set empty link object
            var link = {};

            // if we need an object
            if( href === false ) {

                // changelog
                if( notification_item.type == 'changelog' ) {
                    // set link
                    link = { name: 'changelog-item', params: { id: notification_item.changelog_id }};
                // comments
                } else if( ['like','mention','comment','mention_all','mention_team'].includes(notification_item.type) ) {
                    // set link
                    link = { name: 'feedback-item', params: { id: notification_item.feedback_id }, query: { comment: notification_item.comment_id }};
                // otherwise
                } else {
                    // set link
                    link = { name: 'feedback-item', params: { id: notification_item.feedback_id }};
                }

            // if is href
            } else if( href === true ) {

                // changelog
                if( notification_item.type == 'changelog' ) {
                    // set link
                    link = '/changelog/' + notification_item.changelog_id;
                // comments
                } else if( ['like','mention','comment','mention_all','mention_team'].includes(notification_item.type) ) {
                    // set link
                    link = '/feedback/' + notification_item.feedback_id + '?comment=' + notification_item.comment_id
                // otherwise
                } else {
                    // set link
                    link = '/feedback/' + notification_item.feedback_id;
                }

            }

            // return final link
            return link;

        },

        /**
         *  Open Feedback
         */
        open_widget( view ) {

            // load feedback widget
			window.$sleek.open( ((view === 'changelog') ? 'changelog' : false ) );

        },

        /**
         *  Toogle Sidebar
         */
        sidebar_toggle() {

            // load current layout
            var current_layout = Object.assign([], this.$store.getters.app_settings.layout);

            // if we have a layout small
            if( current_layout && current_layout.includes('layout-small') ) {
                // remove
                current_layout = current_layout.filter(e => e !== 'layout-small');
            } else {
                // add small layout
                current_layout.push('layout-small');
            }

            // save settings
            this.$store.dispatch( 'app_settings_update', {settings_key: 'layout', settings_data: current_layout} );
            
            // done
            return true;

        },

        /**
         *  Load shortcuts
         */
        load_shortcuts() {

            // Create a new KeyboardEvent
            var event = new KeyboardEvent('keydown', {
                bubbles: true,
                cancelable: true,
                key: 'k',
                code: 'KeyK',
                keyCode: 75, // 'K' key
                charCode: 0,
                ctrlKey: false,
                metaKey: true, // 'Command' key on Mac
                shiftKey: false,
                altKey: false
            });

            // Dispatch the event
            document.dispatchEvent(event);

        },

    },


    /** ----------------------------------------------------
     * View => Registred components
     ----------------------------------------------------- */
    components: {
        'popper': Popper
    },


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted() {
        
        // initial load notifications count
        this.notifications_load_count();

        // load notifications count each 30 seconds
        this.$socket.on('user', 'notification', this.notifications_update_count);

    },


    /** ----------------------------------------------------
     * View => On Destroyed
     ----------------------------------------------------- */
    destroyed() {

        // stop feedback checker
        this.$socket.off('user', 'notification', this.notifications_update_count);

    },


    /** ----------------------------------------------------
     * Filter
     ----------------------------------------------------- */
    filters: {
        moment: function (date) {
            return moment.utc(date).fromNow();
        }
    },

}
</script>