import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	tags: [],
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set tags
	TAGS_SET( state, data ) { state.tags = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

	/**
	 * Load tags
	 * @param {*} context 
	 */
	tags_load( context, force = false ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

			// check if item exists
            if( state.tags.length > 0 && force !== true ) {

                // set success
                return resolve( state.tags );

			}

            // load data via API
			ApiService.get('product/' + context.getters.product_active_id, 'tags', function( data ) {

				// set data
				context.commit('TAGS_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Create tags
	 * @param {*} context 
	 */
	tags_create( context, tag_name ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/tag', {name: tag_name}, function( data ) {

                // load tags
                context.dispatch( 'tags_load', true );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Delete tag
	 * @param {*} context 
	 */
	tags_delete( context, tag_id ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/tag/' + tag_id, function( data ) {

				// load tags
                context.dispatch( 'tags_load', true );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

    // get tags
	tags(state) {
		return state.tags;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};