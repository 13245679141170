import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	support: {}
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Add notification
	SUPPORT_SET( state, support_data ) { state.support = support_data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Load support user
	 * @param {*} context 
	 */
	support_load( context ) {

        // call api to get support data
		return new Promise( function(resolve) { 

			// check if we have an user_id
			if( ! context.getters.user_id ) {
				// stop here
				return resolve(false);
			}

            // query data
            ApiService.get('user/' + context.getters.user_id, 'support', function( data ) {

                // set support data
				context.commit('SUPPORT_SET', data); 
				// load support
				context.dispatch( 'support_init' );

				// set success
				return resolve();

			});
            
        });

    },

    /**
	 * Unload support user
	 * @param {*} context 
	 */
	support_unload( context ) {

		// remove support data
		return new Promise( function(resolve) { 

			// set support data
			context.commit('SUPPORT_SET', {}); 
			// shot down support
			window.Intercom('shutdown');
			// load a fresh session for loggedout user
			window.Intercom("boot", {app_id: "bs3h0lq8"});

			// check if $sleek exist
			if( typeof window.$sleek === "object" ) {
				// unload feedback widget user
				window.$sleek.resetUser();
			}

			// set success
			return resolve();
				
		});

    },

    /**
	 * Init support
	 * @param {*} context 
	 */
	support_init( context ) {

		// load Intercom with the data
		window.Intercom("boot", {
			app_id: "bs3h0lq8",
			email: state.support.email,
			user_id: state.support.user_id,
			user_hash: state.support.user_hash,
			name: state.support.name,
			created_at: state.support.created,
			"Plan": state.support.user_plan,
			"Trial": state.support.user_trial,
			"Products Count": state.support.product_count,
			"Product Owner": state.support.product_owner,
			sso_token: state.support.sso_token,
			company: ((state.support.company) ? {
				company_id: state.support.company.company_id,
				name: state.support.company.name,
				plan: state.support.company.plan,
				website: state.support.company.website,
			} : false )
		});

		// check if $sleek exist
		if( typeof window.$sleek === "object" ) {
			// load feedback widget user
			window.$sleek.setUser({
				token: state.support.sso_token,
			});
		}
		
    },

	/**
	 * Update support session
	 * @param {*} context 
	 */
	support_update( context ) {

		// update intercom on url change
		window.Intercom("update");

	}

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get noticiations
	support(state) {
		return state.support;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};