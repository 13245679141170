import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    surveys: {},
	surveys_summary: {},
	surveys_summary_feed: {},
	surveys_responses: {},
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set surveys
    SURVEY_SET( state, data ) { state.surveys = data; },

	// Set surveys
    SURVEY_SET_SUMMARY( state, data ) { state.surveys_summary = data; },

	// Set surveys
    SURVEY_SET_SUMMARY_FEED( state, data ) { state.surveys_summary_feed = data; },

	// Set surveys
    SURVEY_SET_RESPONSES( state, data ) { state.surveys_responses = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

	/**
	 * Load survey list
	 * @param {*} query
	 */
    survey_list( context, query ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/surveys', query, function( data ) {

				// load current survey list
				var surveys = Object.assign({}, context.getters.surveys);

				// if key exist in survey list
				if( surveys.length >= 0 ) {
					// add new item(s)
					surveys.results.push(...data);
					// set data
					surveys.page 		= query.page;
					surveys.has_more 	= ((data.length >= 50) ? true : false );
				// if key does not exist in survey list
				} else {
					// add new item(s)
					Object.assign(surveys, {results: data, page: query.page, has_more: ((data.length >= 20) ? true : false )});
				}

				// set data
				context.commit('SURVEY_SET', surveys); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });
        
    },

    /**
	 * Get single survey
	 * @param {*} {feedback_id, force}
	 */
    survey_get( context, survey_id ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/survey', survey_id, function( data ) {

				// load current survey list
				var surveys = Object.assign({}, context.getters.surveys);

				// check for results
				if( surveys.results ) {
					// update survey
					surveys.results[data.survey_id] = data;
				} else {
					// add survey
					surveys = {results: {[data.survey_id]: data}};
				}

				// set data
				context.commit('SURVEY_SET', surveys); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });
        
    },
    
    /**
	 * Create new survey
	 * @param {*} context 
	 */
    survey_create( context, settings ) {

        // call api to create feedback
		return new Promise( function(resolve, reject) { 

            // create feedback via api
            ApiService.post('product/' + context.getters.product_active_id + '/survey/', settings, function( data ) {

				// load current survey list
				var surveys = Object.assign({}, context.getters.surveys);

				// check for results
				if( surveys.results ) {
					// update survey
					surveys.results[data.survey_id] = data;
					// set data
					context.commit('SURVEY_SET', surveys); 
				}

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },
    
    /**
	 * Update survey
	 * @param {*} context 
	 */
	survey_update( context, {settings, survey_id} ) {

        // call api to update feedback
		return new Promise( function(resolve, reject) { 

            // update feedback via api
            ApiService.update('product/' + context.getters.product_active_id + '/survey', survey_id, settings, function( data ) {

				// load current survey list
				var surveys = Object.assign({}, context.getters.surveys);

				// check for results
				if( surveys.results ) {
					// update survey
					surveys.results[survey_id] = data;
					// set data
					context.commit('SURVEY_SET', surveys);
				}

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Delete survey
	 * @param {*} context 
	 */
    survey_delete( context, survey_id ) {

        // call api to delete feedback
		return new Promise( function(resolve, reject) { 

            // delete feedback via api
            ApiService.delete('product/' + context.getters.product_active_id + '/survey/' + survey_id, function( data ) {

				// load current survey list
				var surveys = Object.assign({}, context.getters.surveys);

				// check for results
				if( surveys.results ) {
					// delete survey
					delete surveys.results[survey_id];
					// set data
					context.commit('SURVEY_SET', surveys); 
				}

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

	/**
	 * Get survey summary
	 * @param {*} {feedback_id, force}
	 */
    survey_summary( context, survey_id ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/survey/' + survey_id, 'summary', function( data ) {

				// load current analytics feed
				var surveys_summary = Object.assign({}, context.getters.surveys_summary);

				// add new item(s)
				Object.assign(surveys_summary, {[survey_id]: data});

				// set data
				context.commit('SURVEY_SET_SUMMARY', surveys_summary); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });
        
    },

	/**
	 * Get survey summary feed
	 * @param {*} {survey_id, query}
	 */
    survey_summary_feed( context, {survey_id, question_id, query} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/survey/' + survey_id + '/summary/' + question_id + '/feed', query, function( data ) {

				// load current analytics feed
				var surveys_summary_feed = Object.assign({}, context.getters.surveys_summary_feed);
				var index_id 			 = survey_id + question_id;

				// if key exist in analytics feed
				if( [index_id] in surveys_summary_feed && query.page !== 0 && surveys_summary_feed[index_id].page <= query.page ) {

					// add new item(s)
					surveys_summary_feed[index_id].results.push(...data);

					// set data
					surveys_summary_feed[index_id].page 		= query.page
					surveys_summary_feed[index_id].has_more 	= ((data.length >= 10) ? true : false )
				
				// if key does not exist in product feed
				} else {

					// add new item(s)
					Object.assign(surveys_summary_feed, {[index_id]: {results: data, page: query.page, has_more: ((data.length >= 10) ? true : false )}});
				
				}

				// set data
				context.commit('SURVEY_SET_SUMMARY_FEED', surveys_summary_feed); 
				
				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });
        
    },

	/**
	 * Get survey responses
	 * @param {*} {survey_id, query}
	 */
    survey_responses( context, {survey_id, query} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/survey/' + survey_id + '/responses', query, function( data ) {

				// load current analytics feed
				var surveys_responses = Object.assign({}, context.getters.surveys_responses);

				// if key exist in analytics feed
				if( [survey_id] in surveys_responses && query.page !== 0 && surveys_responses[survey_id].page <= query.page ) {

					// add new item(s)
					surveys_responses[survey_id].results.push(...data);

					// set data
					surveys_responses[survey_id].page 		= query.page
					surveys_responses[survey_id].has_more 	= ((data.length >= 10) ? true : false )
				
				// if key does not exist in product feed
				} else {

					// add new item(s)
					Object.assign(surveys_responses, {[survey_id]: {results: data, page: query.page, has_more: ((data.length >= 10) ? true : false )}});
				
				}

				// set data
				context.commit('SURVEY_SET_RESPONSES', surveys_responses); 
				
				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });
        
    },

	/**
	 * Get single survey response
	 * @param {*} {survey_id, response_id}
	 */
    survey_response( context, {survey_id, response_id} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/survey/' + survey_id + '/response', response_id, function( data ) {
				
				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });
        
    },

	/**
	 * Delete survey response
	 * @param {*} context 
	 */
    survey_response_delete( context, {survey_id, response_id} ) {

        // call api to delete feedback
		return new Promise( function(resolve, reject) { 

            // delete feedback via api
            ApiService.delete('product/' + context.getters.product_active_id + '/survey/' + survey_id + '/' + response_id, function( data ) {

				// load current analytics feed
				var surveys_responses = Object.assign({}, context.getters.surveys_responses);

				// if key exist in analytics feed
				if( [survey_id] in surveys_responses && surveys_responses[survey_id].results ) {

					// remove item
					surveys_responses[survey_id].results = surveys_responses[survey_id].results.filter(obj => obj.response_id !== response_id);
					
					// set data
					context.commit('SURVEY_SET_RESPONSES', surveys_responses); 
				
				}

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get surveys list
	surveys(state) {
		return state.surveys;
	},

	// get surveys summary
	surveys_summary(state) {
		return state.surveys_summary;
	},

	// get surveys summary feed
	surveys_summary_feed(state) {
		return state.surveys_summary_feed;
	},

	// get surveys resp.
	surveys_responses(state) {
		return state.surveys_responses;
	},
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};