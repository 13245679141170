import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	connection_organization: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set organization data
	CONNECTION_ORGANIZATION_SET( state, data ) { state.connection_organization = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

	/**
	 * Load organization
	 * @param {*} context 
	 */
    connection_organization_load( context, force = false ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

			// check if state.connection_organization is not empty 
            // and we dont want to force reload
            if( (state.connection_organization && Object.keys(state.connection_organization).length === 0 && Object.getPrototypeOf(state.connection_organization) === Object.prototype > 0) == true && force !== true ) {

                // set success
                return resolve( state.connection_organization );

			}

            // load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/connections', 'organization', function( data ) {

				// set data
				context.commit('CONNECTION_ORGANIZATION_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Create organization
	 * @param {*} context 
	 */
    connection_organization_create( context, domain_data ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/connections/organization', {domains: domain_data}, function( data ) {

                // load org
                context.dispatch( 'connection_organization_load', true );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

    /**
	 * Update organization
	 * @param {*} context 
	 */
    connection_organization_update( context, domain_data ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.put('product/' + context.getters.product_active_id + '/connections/organization', {domains: domain_data}, function( data ) {

                // load org
                context.dispatch( 'connection_organization_load', true );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Delete organization
	 * @param {*} context 
	 */
    connection_organization_delete( context ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/connections/organization', function( data ) {

				// load org
                context.dispatch( 'connection_organization_load', true );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

    /**
	 * Load connection admin link
	 * @param {*} context 
	 */
    connection_organization_load_connection( context, type ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/connections/organization/connection', type, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Update organization connection
	 * @param {*} context 
	 */
	connection_organization_update_connection( context, {connection_id, data} ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.put('product/' + context.getters.product_active_id + '/connections/organization/connection/' + connection_id, data, function( data ) {

                // load org
                context.dispatch( 'connection_organization_load', true );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

    /**
	 * Delete organization connection
	 * @param {*} context 
	 */
    connection_organization_delete_connection( context, connection_id ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/connections/organization/connection/' + connection_id, function( data ) {

                // load org
                context.dispatch( 'connection_organization_load', true );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

    // get org
	connection_organization(state) {
		return state.connection_organization;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};