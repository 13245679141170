import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	onboard: {}
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Add notification
	ONBOARD_SET( state, onboard_data ) { state.onboard = onboard_data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Load onboarding
	 * @param {*} context 
	 */
	onboard_load( context ) {

        // call api to get onboarding data
		return new Promise( function(resolve) { 

			// check if we have an user_id
			if( ! context.getters.user_id ) {
				// stop here
				return resolve(false);
			}

            // query data
            ApiService.get('onboard/product/' + context.getters.product_active_id + '/user/' + context.getters.user_id, '', function( data ) {

                // set onboarding data
				context.commit('ONBOARD_SET', data); 

				// set success
				return resolve();

			});
            
        });

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get noticiations
	onboard(state) {
		return state.onboard;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};