import Vue from "vue";
import Vuex from "vuex";

import notification from "./module.notification";
import user from "./module.user";
import user_notifications from "./module.user_notifications";
import product from "./module.product";
import feedback_item from "./module.feedback_item";
import feedback_item_user from "./module.feedback_item_user";
import feedback_item_meta from "./module.feedback_item_meta";
import feedback_query from "./module.feedback_query";
import feedback_vote from "./module.feedback_vote";
import feedback_subscription from "./module.feedback_subscription";
import feedback_comment from "./module.feedback_comment";
import feedback_user from "./module.feedback_user";
import changelog from "./module.changelog";
import stats from "./module.stats";
import satisfaction from "./module.satisfaction";
import promoter from "./module.promoter";
import survey from "./module.survey";
import subscription from "./module.subscription";
import product_developer from "./module.product_developer";
import product_domain from "./module.product_domain";
import product_page from "./module.product_page";
import product_actions from "./module.product_actions";
import product_privacy from "./module.product_privacy";
import product_widget from "./module.product_widget";
import product_feed from "./module.product_feed";
import product_analytics from "./module.product_analytics";
import product_email from "./module.product_email";
import product_email_template from "./module.product_email_template";
import product_meta from "./module.product_meta";
import product_templates from "./module.product_templates";
import product_views from "./module.product_views";
import product_connections from "./module.product_connections";
import settings from "./module.settings";
import settings_state from "./module.settings_state";
import integrations from "./module.integrations";
import segments from "./module.segments";
import tags from "./module.tags";
import onboard from "./module.onboard";
import support from "./module.support";
import analytics from "./module.analytics";
import canvas from "./module.canvas";
import organization from "./module.organization";
import moderation from "./module.moderation";
import wapi from "./module.wapi";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		notification,
        user,
        user_notifications,
        product,
        feedback_item,
        feedback_item_user,
        feedback_item_meta,
        feedback_query,
        feedback_vote,
        feedback_subscription,
        feedback_comment,
        feedback_user,
        changelog,
        stats,
        satisfaction,
        promoter,
        survey,
        subscription,
        product_developer,
        product_domain,
        product_page,
        product_actions,
        product_privacy,
        product_widget,
        product_feed,
        product_analytics,
        product_email,
        product_email_template,
        product_meta,
        product_templates,
        product_views,
        product_connections,
        settings,
        settings_state,
        integrations,
        segments,
        tags,
        onboard,
        support,
        analytics,
        canvas,
        organization,
        moderation,
        wapi
	}
});
