<template>
	<div class="app" :class="layout" id="app">
		
		<transition name="fade">
		<div class="app-container" v-if="product_loaded">

			<div class="app-sidebar hide-scroll" v-if="$route.meta.sidebar == true">
				<sidebar/>
			</div>

			<div class="app-main">
				<router-view/>
			</div>
			
		</div>
		</transition>

		<div class="app-load" v-if="!product_loaded">

			<div v-if="request_error == false">
				<loader type="dark" height="100" size="large"/>
			</div>

			<div v-else>
				<div class="app-load-error">
					<ion-icon name="ios-pulse"></ion-icon>
					<h1>{{request_error_title}}</h1>
					<span>{{request_error_desc}}</span>
					<div class="app-load-error-actions">
						<a class="btn tiny" v-for="button in request_error_buttons" target="_blank" :href="button.src">{{button.title}}</a>
						<a class="btn tiny blue" v-on:click="() => { $store.dispatch( 'user_logout' ).then(() => { current_window.location.reload(); }) }">Refresh</a>
					</div>
				</div>
			</div>
			
		</div>

		<notification/>
		<shortcuts/>

		<div class="device-warning">
			<div>The Sleekplan Admin App is not yet optimized for mobile devices. We recommend you to use another device to get the best experience.</div>
		</div>

	</div>
</template>

<script>
export default {

    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    data() {

		// data
        return {
			product_loaded: this.$store.getters.product_active,
			request_error: false,
			request_error_title: '',
			request_error_desc: '',
			request_error_buttons: [],
			current_window: window
        };
        
    },


	/** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        layout() {

			// get layout & sidebar settings
            var layout_settings 	= Object.assign([], ((this.$store.getters.app_settings && this.$store.getters.app_settings.layout) ? this.$store.getters.app_settings.layout : []));
			var sidebar_settings 	= ((this.$route.meta.type) ? this.$route.meta.type : 1 );

			// check for extended sidebar
			if( sidebar_settings == 2 ) {
				// add to layout settings
				layout_settings.push('layout-extended')
			}

			// return layout settings
			return layout_settings;

        },

	},
	
	
    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted () {

		// load WebSocket
		const load_socket = () => {

			// connect to socket
			this.$socket.connect(() => {

				// subscribe to channels
				this.$socket.subscribe([
					'private-admin-product-' + this.$store.getters.product_active.ID, 
					'private-admin-user-' + this.$store.getters.user_data.ID
				]);

				// map friendly channel names for usage
				this.$socket.map({
					['private-admin-product-' + this.$store.getters.product_active.ID]: 'product',
					['private-admin-user-' + this.$store.getters.user_data.ID]: 'user'
				});

			});

		};

		// remove URL parameter
		const remove_url_parameter = () => {

			// Initialize a flag to track if URL parameters have changed
			let urlParamsChanged = false;

			// Remove 'pid' parameter if it exists
			if(url_params.get('pid')) {
				url_params.delete('pid');
				urlParamsChanged = true; // Update the flag to indicate a change
			}

			// Remove 'product_id' parameter if it exists
			if(url_params.get('product_id')) {
				url_params.delete('product_id');
				urlParamsChanged = true; // Update the flag to indicate a change
			}

			// Only call window.history.replaceState if a change occurred
			if(urlParamsChanged) {
				// Check if there are no remaining URL parameters
				if([...url_params].length === 0) {
					// If no parameters, set URL without '?'
					window.history.replaceState({}, '', window.location.pathname);
				} else {
					// If there are parameters, include them in the URL
					window.history.replaceState({}, '', window.location.pathname + '?' + url_params.toString());
				}
			}

		};

		// check if product is loaded
		if( this.product_loaded == false && this.$store.getters.user_is_loggedin == true ) {
			try {
				// set url params
				var query_string = window.location.search;
				var url_params = new URLSearchParams(query_string);
			} catch (error) {}
			// if not load product
			this.$store.dispatch( 'product_load', ((url_params.get('product_id')) ? url_params.get('product_id') : ((url_params.get('pid')) ? url_params.get('pid') : false)) )
			.then(() => { 
				// set product load to ready
				this.product_loaded = true; 
				// load socket
				load_socket(); 
				// remove url parameter
				remove_url_parameter();
			})
			.catch((e) => { 
				// check if we have a custom error
				if( e && e.key ) {
					// no product
					if( e.key == 'user_product' ) {
						this.request_error_title 	= 'No Product Found';
						this.request_error_desc 	= 'You may have recently deleted your product, in which case your account will also be deleted in the coming days.';
						this.request_error_buttons 	= [{src: 'mailto:support@sleekplan.com', title: 'Contact Support'}];
					// account inactive
					} else if( e.key == 'user_inactive' ) {
						this.request_error_title 	= 'Account Inactive';
						this.request_error_desc 	= 'Your account is currently inactive. Ask your workspace admin to re-activate your account from the admin dashboard.';
						this.request_error_buttons 	= [{src: 'mailto:', title: 'Contact your Admin'}];
					}
				// default network error
				} else {
					this.request_error_title 	= 'Network Error';
					this.request_error_desc 	= 'Unfortunately we could not establish a connection to the server. Please try again in a few minutes.';
					this.request_error_buttons 	= [{src: 'https://status.sleekplan.com', title: 'System Status'}];
				}
				// set error active
				this.request_error = true;
			});
		} else {
			// set product load to ready
			this.product_loaded = true;
			// load socket
			load_socket();
		}

		// load support
		this.$store.dispatch( 'support_load' ).then(() => {});

		// load analytics
		this.$store.dispatch( 'analytics_load' );

	},


	/** ----------------------------------------------------
     * Watch
     ----------------------------------------------------- */
    watch: {
        $route() {

			// update support session
			this.$store.dispatch( 'support_update' );

			// update view
			this.$store.dispatch( 'analytics_view' );
            
        }
    }

}
</script>