/**
 * **************************
 * Helper:
 * Number manager
 * **************************
 */

const NumberHelper = {

	// initally load ApiService
	format( num, digits = 0 ) {

        // lookup
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];

        // regex
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

        // build
        var item = lookup.slice().reverse().find(function(item) {
            return num >= item.value;
        });

        // return
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";

    },

}
    
export default NumberHelper;