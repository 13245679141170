import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    product_feed: {},
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set product feed
    PRODUCT_FEED_SET( state, feed ) { state.product_feed = feed; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get Feed
	 * @param {*} context 
	 */
	product_feed_get( context, query ) {

		// set query name
		var filter_name = query.filter;

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/feed', query, function( data ) {

				// load current feedback query
				var product_feed = Object.assign({}, context.getters.product_feed);

				// if key exist in product feed
				if( [filter_name] in product_feed && query.page !== 0 && product_feed[filter_name].page <= query.page ) {

					// add new item(s)
					product_feed[filter_name].results.push(...data);

					// set data
					product_feed[filter_name].page 		= query.page
					product_feed[filter_name].has_more 	= ((data.length > 0) ? true : false )
				
				// if key does not exist in product feed
				} else {

					// add new item(s)
					Object.assign(product_feed, {[filter_name]: {results: data, page: query.page, has_more: ((data.length > 0) ? true : false )}});
				
				}

				// set data
				context.commit('PRODUCT_FEED_SET', product_feed); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// product domain data
	product_feed(state) {
		return state.product_feed;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};