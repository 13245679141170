import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
    developer_api: false,
    developer_webhooks: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set satisfaction
    DEVELOPER_SET_API( state, data ) { state.developer_api = data; },
    DEVELOPER_SET_WEBHOOKS( state, data ) { state.developer_webhooks = data; }

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get API key
	 * @param {*} context 
	 * @param {object} query 
	 */
	developer_get_api( context, query ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/satisfaction', query, function( data ) {

				// set data
				context.commit('DEVELOPER_SET_API', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Get webhooks
	 * @param {*} context 
	 * @param {object} query 
	 */
	developer_get_webhooks( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id, 'webhooks', function( data ) {

				// set data
				context.commit('DEVELOPER_SET_WEBHOOKS', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Create a webhook
	 * @param {*} context 
	 * @param {object} query 
	 */
	developer_create_webhook( context, webhook_data ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/webhooks', webhook_data, function( data ) {

				// set data
				context.commit('DEVELOPER_SET_WEBHOOKS', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Delete a webhook
	 * @param {*} context 
	 * @param {object} query 
	 */
	developer_delete_webhook( context, webhook_id ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/webhooks/' + webhook_id, function( data ) {

				// set data
				context.commit('DEVELOPER_SET_WEBHOOKS', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Rotate keys
	 * @param {*} context 
	 * @param {object} rotation_data 
	 */
	developer_rotate_keys( context, rotation_data ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/keys/rotate', rotation_data, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// developer api key
	developer_api(state) {
		return state.developer_api;
    },

    // developer webhooks
	developer_webhooks(state) {
		return state.developer_webhooks;
    },

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};