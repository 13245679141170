<template>
    <!-- Modal list: Shortcuts -->
    <modal-list
        ref="shortcutList"
        v-bind:items="shortcut_list_results"
        v-bind:loading="shortcut_list_loading"
        v-on:search="shortcut_list_load"
        v-on:selected="shortcut_list_select"
        v-on:close="shortcut_close"
        enable_search="Search..."
        custom_class="shortcuts"
    />
</template>

<script>
import ModalList from '@/components/module.loc.modal-list';

export default {


    name: 'shortcut',


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
		return {

            // open state
            shortcut_open: false,

            // shortcut view
            shortcut_view: '',
            
            // shortcut modal list
            shortcut_list_items: false,
            shortcut_list_search: false,
            shortcut_list_loading: false,

            // global shortcuts
            shortcuts_global: [
                {
                    img: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='s-ion-icon'%3E%3Cpath d='M376.2 224H268l52.4-186.9c.9-4.5-4.6-7.1-7.2-3.4L129.5 274.6c-3.8 5.6-.2 13.4 6.3 13.4H244l-52.4 186.9c-.9 4.5 4.6 7.1 7.2 3.4l183.7-240.8c3.7-5.7.2-13.5-6.3-13.5z'%3E%3C/path%3E%3C/svg%3E",
                    key: 'Create a new post',
                    data: {link: '/feedback/add'},
                    meta: [{class: 'shortcut-icon', icon: '⌘', value: 'P'}],
                },
                {
                    img: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='s-ion-icon'%3E%3Cpath d='M376.2 224H268l52.4-186.9c.9-4.5-4.6-7.1-7.2-3.4L129.5 274.6c-3.8 5.6-.2 13.4 6.3 13.4H244l-52.4 186.9c-.9 4.5 4.6 7.1 7.2 3.4l183.7-240.8c3.7-5.7.2-13.5-6.3-13.5z'%3E%3C/path%3E%3C/svg%3E",
                    key: 'Create a new update',
                    data: {link: '/changelog/add'},
                    meta: [{class: 'shortcut-icon', icon: '⌘', value: 'U'}],
                },
                {
                    img: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='s-ion-icon'%3E%3Cpath d='M376.2 224H268l52.4-186.9c.9-4.5-4.6-7.1-7.2-3.4L129.5 274.6c-3.8 5.6-.2 13.4 6.3 13.4H244l-52.4 186.9c-.9 4.5 4.6 7.1 7.2 3.4l183.7-240.8c3.7-5.7.2-13.5-6.3-13.5z'%3E%3C/path%3E%3C/svg%3E",
                    key: 'Load saved search',
                    data: {view: 'shortcut_action_views'},
                    meta: [{class: 'shortcut-icon', icon: '⌘', value: 'V'}],
                }
            ],

            // local shortcuts
            shortcuts_local: [
                {
                    pages: ['feedback', 'feedback-item', 'activites', 'changelog', 'user'],
                    key: 'Add saved search',
                    data: {view: 'shortcut_action_save_view'},
                }
            ],

        }

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        current_page() {
            return this.$route.name;
        },

        shortcut_list_results() {
            return this.shortcut_list_search ? this.shortcut_list_search : this.shortcut_list_items;
        }

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  GLOBAL => Listener for shortcut
         */
        shortcut_listener(event) {

            // check if is mac
            const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

            // for mac
            if (isMac && event.metaKey && event.key === 'k') {

                // listen to cmd + K
                event.preventDefault();
                this.shortcut_load();

            // for others
            } else if (!isMac && event.ctrlKey && event.key === 'k') {

                // listen to str + K
                event.preventDefault();
                this.shortcut_load();

            // internal shortcuts
            } else if( this.shortcut_open === true && !this.shortcut_view && (event.metaKey || event.ctrlKey) ) {

                // check for shortcut
                var _shortcut = this.shortcuts_global.find(item => 
                    item.meta.some(meta => meta.value === event.key.toUpperCase())
                );

                // if we have an shortcut
                if(_shortcut) {
                    event.preventDefault();
                    this.shortcut_list_select( {data:_shortcut.data, success: () => {}, error: () => {}} )
                }

            // escape key
            } else if( this.shortcut_open === true && (event.key === 'Escape' || event.key === 'Esc') ) {

                // close
                event.preventDefault();
                this.$refs.shortcutList.list_hide();

            }

        },

        /**
         *  GLOBAL => Modal load
         */
        shortcut_load() {

            // set open state
            this.shortcut_open = true;

            // load local items
            var local_shortcut_items = this.shortcuts_local.filter(shortcut => {
                // Check if the shortcut has a pages property and filter based on current_page
                return shortcut.pages && shortcut.pages.includes(this.current_page);
            });

            // set shortcut items
            this.shortcut_list_items = [...local_shortcut_items, ...this.shortcuts_global];

            // load modal
            this.$refs.shortcutList.list_show();

        },

        /**
         *  GLOBAL => Modal close
         */
        shortcut_close() {

            // reset all data
            this.shortcut_view          = '';
            this.shortcut_list_items    = false;
            this.shortcut_list_search   = false;
            this.shortcut_list_loading  = false;

            // set open state
            this.shortcut_open = false;
            
        },

        /**
         *  GLOBAL => Shortcut load
         */
        shortcut_list_load( search = '' ) {

            // if we have a search
            if (search) {
                this.shortcut_list_search = this.shortcut_list_items.filter(item =>
                    item.key.toLowerCase().includes(search.toLowerCase())
                );
            // otherwise
            } else {
                this.shortcut_list_search = this.shortcut_list_items;
            }

            // done
            return true;
        
        },

        /**
         *  GLOBAL => Shortcut select
         */
        shortcut_list_select( {data, success, error} ) {

            // if we have an link
            if( 'link' in data ) {

                // open link & close modal
                this.$router.push(data.link);
                this.$refs.shortcutList.list_hide();

            // if we have a specific view
            } else if( 'view' in data || this.shortcut_view ) {
                
                // set method name
                var method_name = ((this.shortcut_view) ? this.shortcut_view : data.view);

                // call method
                if (this[method_name] && typeof this[method_name] === 'function') {
                    // load method
                    this[method_name]( {data, success, error} );
                }

            }

        },

        /** 
         * --------------------------------------------------------------- 
         * Methods 
         * --------------------------------------------------------------- 
         * */

        /**
         *  Method: Views
         */
        shortcut_action_views( {data, success, error} ) {
            
            // set view
            this.shortcut_view = data;

            // set data
            var set_data = ( data ) => {

                // reset current data
                this.shortcut_list_items = [];
                this.shortcut_list_search = false;

                // load new data
                data.forEach(element => {
                    var item_data = {
                        key: element.name,
                        data: {link: element.data},
                        meta: []
                    };
                    if( element.shared ) {
                        item_data.meta.push({class: 'badge', value: 'shared'})
                    }
                    this.shortcut_list_items.push(item_data);
                });

            };

            // check if we have views already
            if( this.$store.getters.product_views !== false ) {
                set_data(this.$store.getters.product_views);
                return;
            }

            // set loading state
            this.shortcut_list_loading = true;

            // get views
			this.$store.dispatch( 'product_views_get', {} )
			.then((data) => {
                // reset loading state
                this.shortcut_list_loading = false;
                // set data
                set_data(data);
            }).catch(() => {
                // reset loading state
                this.shortcut_list_loading = false;
            });

        },

        /**
         *  Method: Save Views
         */
        shortcut_action_save_view( {data, success, error} ) {

            // forward data to save view page
            this.$router.push({ path: '/account/views', query: { 
                name:   this.$route.name,
                query:  JSON.stringify(this.$route.query),
                params: JSON.stringify(this.$route.params), 
            } });

            // hide list
            this.$refs.shortcutList.list_hide();

        }

    },


    /** ----------------------------------------------------
     * View => Registred components
     ----------------------------------------------------- */
    components: {
        'modal-list': ModalList,
    },


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted() {
        
        // listen to keydown events
        window.addEventListener('keydown', this.shortcut_listener);

    },


    /** ----------------------------------------------------
     * View => Before Destroy
     ----------------------------------------------------- */

    beforeDestroy() {

        // stop listening to keydown events
        window.removeEventListener('keydown', this.shortcut_listener);

    },

}
</script>
