import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    moderation_items: false,
    moderation_query: {},
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set active product
    MODERATION_SET( state, data ) { state.moderation_items = data; },

    // Set feedback query
    MODERATION_SET_QUERY( state, data ) { state.moderation_query = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Query moderation
	 * @param {*} query
	 */
    moderation_query( context, query ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 
            
            // load data via API
            ApiService.query('product/' + context.getters.product_active_id + '/moderation', query, function( data ) {

                // load new product into our product store
                context.dispatch( 'moderation_add', data.items );

                // load new product into our product store
                context.dispatch( 'moderation_add_query', {has_more: data.has_more, page: data.page} );

                // set success
                return resolve(data);

            }, function( data ) {

                // load new product into our product store
                context.dispatch( 'moderation_add', data.items );

                // load new product into our product store
                context.dispatch( 'moderation_add_query', {has_more: data.has_more, page: data.page} );

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Add moderation item(s) to store
	 * @param {*} context 
	 */
	moderation_add( context, items ) {

        // load current moderation items
        var moderation_items = Object.assign({}, context.getters.moderation_items);

        // add new item(s)
        if(moderation_items) Object.assign(moderation_items, items);
        else moderation_items = items;

        // set feedback items
        context.commit('MODERATION_SET', moderation_items); 

    },

    /**
	 * Add query to store
	 * @param {*} context 
	 */
	moderation_add_query( context, query ) {

        // load current feedback query
        var moderation_query = Object.assign({}, context.getters.moderation_query);

        // add new item(s)
        Object.assign(moderation_query, query);

        // set feedback query
        context.commit('MODERATION_SET_QUERY', moderation_query); 

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get moderation items
	moderation_items(state) {
		return state.moderation_items;
    },
    
    // get moderation query
	moderation_query(state) {
		return state.moderation_query;
	},
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};