import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    feedback_user_items: false,
    feedback_user_query: {},
    feedback_user_list: [],
    feedback_user_feed: {},
    feedback_user_outbound_feed: {},
    feedback_user_segment: {},
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set active product
    FEEDBACK_USER_SET( state, data ) { state.feedback_user_items = data; },

    // Set feedback query
    FEEDBACK_USER_SET_QUERY( state, data ) { state.feedback_user_query = data; },

    // Set feedback lists
    FEEDBACK_USER_SET_LIST( state, data ) { state.feedback_user_list = data; },

    // Set user feed
    FEEDBACK_USER_SET_FEED( state, data ) { state.feedback_user_feed = data; },

    // Set user outbound feed
    FEEDBACK_USER_SET_OUTBOUND_FEED( state, data ) { state.feedback_user_outbound_feed = data; },

    // Set user segments
    FEEDBACK_USER_SET_SEGMENT( state, data ) { state.feedback_user_segment = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Query feedback_users
	 * @param {*} query
	 */
    feedback_user_query( context, query ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 
            
            // set query name
            var query_name = JSON.stringify(query.query);

            // load data via API
            ApiService.query('feedback/' + context.getters.product_active_id + '/users/', Object.assign({}, query.query, {page: query.page}), function( data ) {

                // load new product into our product store
                context.dispatch( 'feedback_user_add', {items: data.items} );

                // load new product into our product store
                context.dispatch( 'feedback_user_add_query', {query: {has_more: data.has_more, page: data.page}, query_name: query_name} );

                // set user list
                context.dispatch( 'feedback_user_add_list', {list: data.order, query_name: query_name});

                // set success
                return resolve(data);

            }, function( data ) {

                // load new product into our product store
                context.dispatch( 'feedback_user_add', {items: data.items} );

                // load new product into our product store
                context.dispatch( 'feedback_user_add_query', {query: {has_more: data.has_more, page: data.page}, query_name: query_name} );

                // set user list
                context.dispatch( 'feedback_user_add_list', {list: data.order, query_name: query_name});

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Get Events Feed for user
	 * @param {*} context 
	 */
	feedback_user_feed( context, feedback_user_id ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/feed/user', feedback_user_id, function( data ) {

                // load current feed
                var feedback_user_feed = Object.assign({}, context.getters.feedback_user_feed);

                // add new item(s)
                Object.assign(feedback_user_feed, {[feedback_user_id]: data});

				// set data
				context.commit('FEEDBACK_USER_SET_FEED', feedback_user_feed); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Get Events Feed for user
	 * @param {*} context 
	 */
	feedback_user_outbound_feed( context, feedback_user_id ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/outbound', {user_id: feedback_user_id, per_page: 6}, function( data ) {

                // load current feed
                var feedback_user_outbound_feed = Object.assign({}, context.getters.feedback_user_outbound_feed);

                // add new item(s)
                Object.assign(feedback_user_outbound_feed, {[feedback_user_id]: data});

				// set data
				context.commit('FEEDBACK_USER_SET_OUTBOUND_FEED', feedback_user_outbound_feed); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Load / refresh feedback_user
	 * @param {*} {feedback_user_id, force}
	 */
    feedback_user_load( context, {feedback_user_id, force} ) {

        // call api to get single feedback_user item
		return new Promise( function(resolve, reject) { 

            // check if item exists
            if( state.feedback_user_items[feedback_user_id] && force !== true ) {

                // set success
                return resolve( state.feedback_user_items[feedback_user_id] );

            // if feedback_user item doesnt exist try to load
            } else {

                // load data via API
                ApiService.get('feedback/' + context.getters.product_active_id + '/user', feedback_user_id, function( data ) {

                    // load new product into our product store
                    context.dispatch( 'feedback_user_add', {item: data} );

                    // set success
                    return resolve(data);

                }, function( data ) {

                    // set rejection
                    return reject(data);

                });
            
            }

        });
        
    },

    /**
	 * Create feedback_user
	 * @param {*} {feedback_user_id, force}
	 */
    feedback_user_create( context, feedback_user_data ) {

        // call api to create feedback user
		return new Promise( function(resolve, reject) { 

            // create feedback via api
            ApiService.post('feedback/' + context.getters.product_active_id + '/user', feedback_user_data, function( data ) {

                // get single product
                context.dispatch( 'feedback_user_load', {feedback_user_id: data.user_id, force: true} );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Edit User
	 * @param {*} context 
	 * @param {object} user_edit 
	 */
    feedback_user_edit( context, {feedback_user_id, feedback_user_data} ) {
		
		// call api to edit user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.update('feedback/' + context.getters.product_active_id + '/user', feedback_user_id, feedback_user_data, function( data ) {

				// get single user
                context.dispatch( 'feedback_user_load', {feedback_user_id: data.user_id, force: true} );

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},

    /**
	 * Delete feedback_user
	 * @param {*} context 
	 */
	feedback_user_delete( context, feedback_user_id ) {

        // call api to update feedback
		return new Promise( function(resolve, reject) { 

            // delete feedback_user via api
            ApiService.delete('feedback/' + context.getters.product_active_id + '/user/' + feedback_user_id, function( data ) {

                // remove single product
                context.dispatch( 'feedback_user_remove', data.user_id );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },


	/**
	 * Load feedback_user segment
	 * @param {*} feedback_user_id
	 */
    feedback_user_load_segment( context, feedback_user_id ) {

        // call api to get single feedback_user item
		return new Promise( function(resolve, reject) { 

            // load data via API
            ApiService.get('feedback/' + context.getters.product_active_id + '/user/' + feedback_user_id, 'segment', function( data ) {

                // load current feed
                var feedback_user_segment = Object.assign({}, context.getters.feedback_user_segment);

                // add new item(s)
                Object.assign(feedback_user_segment, {[feedback_user_id]: ((data.segments) ? data.segments : [])});

                // set data
                context.commit('FEEDBACK_USER_SET_SEGMENT', feedback_user_segment); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Add feedback_user item(s) to store
	 * @param {*} context 
	 */
	feedback_user_add( context, {items, item} ) {

        // load current feedback_user items
        var feedback_user_items = Object.assign({}, context.getters.feedback_user_items);

        // for items object
        if( items ) {
            // add new item(s)
            if(feedback_user_items) Object.assign(feedback_user_items, items);
            else feedback_user_items = items;
        }

        // for single item
        if( item ) {
            // add new item(s)
            feedback_user_items[item.user_id] = item;
        }

        // set feedback items
        context.commit('FEEDBACK_USER_SET', feedback_user_items); 

    },

    /**
	 * Add query to store
	 * @param {*} context 
	 */
	feedback_user_add_query( context, {query, query_name} ) {

        // load current feedback query
        var feedback_user_query = Object.assign({}, context.getters.feedback_user_query);

        // add new item(s)
        Object.assign(feedback_user_query, {[query_name]: query});

        // set feedback query
        context.commit('FEEDBACK_USER_SET_QUERY', feedback_user_query); 

    },

    /**
	 * Add query to store
	 * @param {*} context 
	 */
	feedback_user_add_list( context, {list, query_name} ) {

        // load current feedback query
        var feedback_user_list = Object.assign({}, context.getters.feedback_user_list);

        // check if is existing
        if( ! feedback_user_list[query_name] ) 
            feedback_user_list[query_name] = [];

        // add new item(s)
        feedback_user_list[query_name].push(...list)

        // set feedback query
        context.commit('FEEDBACK_USER_SET_LIST', feedback_user_list); 

    },

    /**
	 * Remove feedback_user item(s) to store
	 * @param {*} context 
	 */
	feedback_user_remove( context, item_id ) {

        // load current feedback_user items
        var feedback_user_items = Object.assign({}, context.getters.feedback_user_items);
        // remove hcangelog item
        delete feedback_user_items[item_id];

        // set feedback items
        context.commit('FEEDBACK_USER_SET', feedback_user_items); 

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get feedback feedback_users
	feedback_user_items(state) {
		return state.feedback_user_items;
    },
    
    // get feedback feedback_users
	feedback_user_query(state) {
		return state.feedback_user_query;
    },
    
    // get feedback user list
    feedback_user_list(state) {
        return state.feedback_user_list;
    },

    // get feedback user feed
    feedback_user_feed(state) {
        return state.feedback_user_feed;
    },

    // get feedback user feed
    feedback_user_outbound_feed(state) {
        return state.feedback_user_outbound_feed;
    },

    // get feedback user feed
    feedback_user_segment(state) {
        return state.feedback_user_segment;
    }
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};