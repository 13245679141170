import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    feedback_item_user: {},
	feedback_item_stats: {},
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set product feed
    FEEDBACK_ITEM_USER_SET( state, data ) { state.feedback_item_user = data; },

	// Set product feed
    FEEDBACK_ITEM_STATS_SET( state, data ) { state.feedback_item_stats = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get Feed
	 * @param {*} context 
	 */
	feedback_item_user_load( context, {feedback_id, query} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('feedback/' + context.getters.product_active_id + '/item_meta/' + feedback_id + '/user', query, function( data ) {

                // prepare data
				let merged_data = Object.assign( {}, state.feedback_item_user, {[feedback_id]: {[query.filter]: data}} );

				// set data
				context.commit('FEEDBACK_ITEM_USER_SET', merged_data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Get Feed stats
	 * @param {*} context 
	 */
	feedback_item_stats_load( context, feedback_id ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('feedback/' + context.getters.product_active_id + '/item_meta/' + feedback_id, 'stats', function( data ) {

                // prepare data
				let merged_data = Object.assign( {}, state.feedback_item_stats, {[feedback_id]: data} );

				// set data
				context.commit('FEEDBACK_ITEM_STATS_SET', merged_data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// feedback item userlist
	feedback_item_user(state) {
		return state.feedback_item_user;
    },

	// feedback item userlist
	feedback_item_stats(state) {
		return state.feedback_item_stats;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};