import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    organization_products: {},
	organization_user: false,
    organization_user_products: {},
	organization_invitation: false
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Add organization products
	ORGANIZATION_PRODUCTS_SET( state, product_data ) { state.organization_products = product_data; },

	// Add organization user products
	ORGANIZATION_USER_SET( state, organization_user ) { state.organization_user = organization_user; },

    // Add organization user products
	ORGANIZATION_USER_PRODUCTS_SET( state, user_products ) { state.organization_user_products = user_products; },

	// Set availible invitations for product
    ORGANIZATION_SET_INVITATION( state, data ) { state.organization_invitation = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Get all products for organization
	 * @param {*} context 
	 */
    organization_products_get( context ) {

        // call api to get products
        return new Promise( function(resolve, reject) { 
    
            // load data via API
            ApiService.get('organization', context.getters.product_active_id + '/product', function( data ) {
    
                // set availible products
                context.commit('ORGANIZATION_PRODUCTS_SET', data); 
    
                // set success
                return resolve(data);
    
            }, function( data ) {
    
                // set rejection
                return reject(data);
    
            });
    
        });
        
    },

	/**
	 * Organization create product
	 * @param {*} context 
	 * @param {object} product_data
	 */
    organization_product_create( context, product_data ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('/organization/' + context.getters.product_active_id + '/product', product_data, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},

	/**
	 * Get all organization user
	 * @param {*} context 
     * @param {int} user_id
	 */
    organization_user_get( context, user_id ) {

        // call api to get products
        return new Promise( function(resolve, reject) { 
    
            // load data via API
            ApiService.get('organization/' + context.getters.product_active_id, 'user', function( data ) {
                
                // set availible products
                context.commit('ORGANIZATION_USER_SET', data); 
    
                // set success
                return resolve(data);
    
            }, function( data ) {
    
                // set rejection
                return reject(data);
    
            });
    
        });
        
    },

	/**
	 * Add organization user
	 * @param {*} context 
	 * @param {object} user_data 
	 */
	organization_user_add( context, user_data ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('organization/' + context.getters.product_active_id + '/user', user_data, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},
	
	/**
	 * Get all products for organization user
	 * @param {*} context 
     * @param {int} user_id
	 */
    organization_user_products_get( context, user_id ) {

        // call api to get products
        return new Promise( function(resolve, reject) { 
    
            // load data via API
            ApiService.get('organization/' + context.getters.product_active_id + '/user', user_id + '/product', function( data ) {
                
                // load current user products
                var organization_user_products = Object.assign({}, context.getters.organization_user_products);
                
                // set user products
                organization_user_products[user_id] = data;
                
                // set availible products
                context.commit('ORGANIZATION_USER_PRODUCTS_SET', organization_user_products); 
    
                // set success
                return resolve(data);
    
            }, function( data ) {
    
                // set rejection
                return reject(data);
    
            });
    
        });
        
    },

    /**
	 * Organization add product user
	 * @param {*} context 
	 * @param {object} {user_id, product_id}
	 */
    organization_user_products_add( context, {user_id, product_id} ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('/organization/' + context.getters.product_active_id + '/user/' + user_id + '/product/' + product_id, {}, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},

	/**
	 * Organization update product user
	 * @param {*} context 
	 * @param {object} user_data 
	 */
    organization_user_products_update( context, {user_id, user_data, product_id} ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.update('/organization/' + context.getters.product_active_id + '/user/' + user_id + '/product', product_id, user_data, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},
	
	/**
	 * Organization delete product user
	 * @param {*} context 
	 * @param {object} {user_id, product_id}
	 */
    organization_user_products_remove( context, {user_id, product_id} ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('/organization/' + context.getters.product_active_id + '/user/' + user_id + '/product/' + product_id, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

    /**
	 * Organization delete user
	 * @param {*} context 
	 * @param {int} user_id 
	 */
    organization_user_remove( context, user_id ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('/organization/' + context.getters.product_active_id + '/user/' + user_id, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

	/**
	 * Get organization invitation
	 * @param {*} context 
	 * @param {object} product_data 
	 */
	organization_invitation_get( context ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('/organization', context.getters.product_active_id +  '/invitation', function( data ) {

				// set product user
				context.commit('ORGANIZATION_SET_INVITATION', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

	},

	/**
	 * Delete organization invitation
	 * @param {*} context 
	 * @param {int} user_id 
	 */
	organization_invitation_remove( context, invitation_id ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('/organization/' + context.getters.product_active_id + '/invitation/' + invitation_id, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get organization products
	organization_products(state) {
		return state.organization_products;
	},

	// get organization user
    organization_user(state) {
		return state.organization_user;
	},

    // get organization user products
    organization_user_products(state) {
		return state.organization_user_products;
	},

	// get organization invitation
	organization_invitation(state) {
		return state.organization_invitation;
	},

	// get organization id
	organization_id: (state, getters) => {
        return ((
			state && 
			getters &&
			getters.product_active.organization_id && 
			parseInt(getters.product_active.organization_id) !== 0
		) ? parseInt(getters.product_active.organization_id) : false);
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};