<template>
    <div class="app-notification">
        <transition-group name="notification" tag="div">
        <div v-for="(item, key, index) in notifications" class="notification-item" :class="item.type" :key="key">
            <div class="notification-inner" v-on:click="notification_action(key)">
                <div class="notification-icon">
                    <svg v-if="item.type == 'success'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M416 128L192 384l-96-96"/></svg>
                    <svg v-if="item.type == 'error'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Warning</title><path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z"/></svg>
                    <svg v-if="item.type == 'info'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Information</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="40" d="M196 220h64v172"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="40" d="M187 396h138"/><path d="M256 160a32 32 0 1132-32 32 32 0 01-32 32z"/></svg>
                    <svg v-if="item.type == 'system'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Flash</title><path d="M315.27 33L96 304h128l-31.51 173.23a2.36 2.36 0 002.33 2.77h0a2.36 2.36 0 001.89-.95L416 208H288l31.66-173.25a2.45 2.45 0 00-2.44-2.75h0a2.42 2.42 0 00-1.95 1z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                </div>
                <div class="notification-message">{{item.message}}</div>
            </div>
            <div class="notification-timer">
                <div :style="{'transition-duration': (item.timeout/1000) + 's!important'}" :ref="key"></div>
            </div>
        </div>
        </transition-group>
    </div>
</template>

<script>
export default {

    name: 'notification',


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        notifications() {
            return this.$store.getters.notifications;
        }

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
     methods: {

        /**
         *  Submit feedback comments
         */
        notification_action( notification_id ) {

            // close ntifcation
            this.$store.dispatch( 'notification_remove', notification_id ); 

        },

    }

}
</script>
