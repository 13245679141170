<template>
    <div :class="loader_class" :style="loader_style">
        <svg v-if="!blank" version="1.1" id="loader" class="loader-animation" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" xml:space="preserve">
            <path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z" transform="rotate(134.854 25 25)">
                <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform>
            </path>
        </svg>
    </div>
</template>

<script>
export default {

    name: 'loader',

    props: ['type', 'height', 'size', 'blank'],


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        return {
            loader_style: ((this.height)  ? 'height: ' + this.height + 'px;' : '' ),
            loader_class: this.type + ' app-loader ' + this.size
        }
    }

}
</script>
