import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    feedback_item_meta: {},
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set feedback meta
    FEEDBACK_ITEM_META_SET( state, data ) { state.feedback_item_meta = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get feedback meta data
	 * @param {*} context 
	 */
	feedback_item_meta_load( context, feedback_id ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('feedback/' + context.getters.product_active_id + '/item/' + feedback_id, 'meta', function( data ) {

                // load current feed
                var feedback_item_meta = Object.assign({}, context.getters.feedback_item_meta);

                // add new meta data
                Object.assign(feedback_item_meta, {[feedback_id]: ((data.meta) ? data.meta : {})});

				// set data
				context.commit('FEEDBACK_ITEM_META_SET', feedback_item_meta); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Update feedback meta data
	 * @param {*} context 
	 */
	feedback_item_meta_update( context, {feedback_id, key, value} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load current feed
			var feedback_item_meta = Object.assign({}, context.getters.feedback_item_meta);
			var feedback_item_meta_backup = Object.assign({}, feedback_item_meta);

			if( feedback_item_meta[feedback_id] ) {
				// update existing meta data
				Object.assign(feedback_item_meta[feedback_id], {[key]: {value: value}});
			} else {
				// add new meta data
				Object.assign(feedback_item_meta, {[feedback_id]: {[key]: {value: value}}});
			}

			// set data
			context.commit('FEEDBACK_ITEM_META_SET', feedback_item_meta); 

			// load data via API
			ApiService.update('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/meta', '', {key: key, value: value}, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// reset data
				context.commit('FEEDBACK_ITEM_META_SET', feedback_item_meta_backup); 

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Delete feedback meta data
	 * @param {*} context 
	 */
	feedback_item_meta_delete( context, {feedback_id, key} ) {

		// load current feed
		var feedback_item_meta = Object.assign({}, context.getters.feedback_item_meta);
		var feedback_item_meta_backup = Object.assign({}, feedback_item_meta);

		if( feedback_item_meta[feedback_id] ) {
			delete feedback_item_meta[feedback_id][key];
		}

		// set data
		context.commit('FEEDBACK_ITEM_META_SET', feedback_item_meta); 

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('feedback/' + context.getters.product_active_id + '/item/' + feedback_id + '/meta/' + key, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set data
				context.commit('FEEDBACK_ITEM_META_SET', feedback_item_meta_backup); 

				// set rejection
				return reject(data);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// feedback item userlist
	feedback_item_meta(state) {
		return state.feedback_item_meta;
    },

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};