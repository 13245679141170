import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	segments: {},
    segments_settings: {}
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set segments
	SEGMENTS_SET( state, data ) { state.segments = data; },

    // Set segment settings
	SEGMENTS_SET_SETTINGS( state, data ) { state.segments_settings = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

	/**
	 * Load segments
	 * @param {*} context 
	 */
	 segment_load( context ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/segment', 'list', function( data ) {

				// set data
				context.commit('SEGMENTS_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Create segment
	 * @param {*} context 
	 */
	segment_create( context, segment_data ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/segment/item', segment_data, function( data ) {

				// set data
				context.commit('SEGMENTS_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Update segment
	 * @param {*} context 
	 */
	segment_update( context, {segment_id, segment_data} ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.update('product/' + context.getters.product_active_id + '/segment/item', segment_id, segment_data, function( data ) {

				// set data
				context.commit('SEGMENTS_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Delete segment
	 * @param {*} context 
	 */
	segment_delete( context, segment_id ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/segment/item/' + segment_id, function( data ) {

				// set data
				context.commit('SEGMENTS_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},
	
	/**
	 * Load segment settings
	 * @param {*} context 
	 */
	segment_settings_load( context ) {

        // call api to set product
		return new Promise( function(resolve, reject) { 

            // load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/segment', 'settings', function( data ) {

				// set data
				context.commit('SEGMENTS_SET_SETTINGS', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});
            
        });

	},

	/**
	 * Add segment field
	 * @param {*} context 
	 * @param {object} segment_settings 
	 */
	segment_settings_field_add( context, segment_field ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/segment/settings', segment_field, function( data ) {

                // set data
				context.commit('SEGMENTS_SET_SETTINGS', data);

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

	/**
	 * Remove segment field
	 * @param {*} context 
	 * @param {object} segment_settings 
	 */
	 segment_settings_field_remove( context, segment_field_id ) {

        // call api to get products
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/segment/settings/?field_id=' + encodeURIComponent(segment_field_id), function( data ) {

                // set data
				context.commit('SEGMENTS_SET_SETTINGS', data);

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

        });

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

    // get segments
	segments(state) {
		return state.segments;
	},

	// get segments settings
	segments_settings(state) {
		return state.segments_settings;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};