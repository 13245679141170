import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    product_email_templates: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set domain data
    PRODUCT_EMAIL_TEMPLATES_SET( state, email_templates ) { state.product_email_templates = email_templates; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get Custom Email
	 * @param {*} context 
	 */
	product_email_templates_get( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/email', 'templates', function( data ) {

				// set data
				context.commit('PRODUCT_EMAIL_TEMPLATES_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Update Email Template
	 * @param {*} context 
	 * @param {string} template_key 
     * @param {object} template_data 
	 */
    product_email_template_update( context, {template_key, template_data} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.update('product/' + context.getters.product_active_id + '/email/template', template_key, template_data, function( data ) {

				// set data
				context.commit('PRODUCT_EMAIL_TEMPLATES_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Remove/ Reset Email Template
	 * @param {*} context 
     * @param {string} template_key 
	 */
	product_email_template_remove( context, template_key ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/email/template/' + template_key, function( data ) {

				// set data
				context.commit('PRODUCT_EMAIL_TEMPLATES_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// product domain data
	product_email_templates(state) {
		return state.product_email_templates;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};