import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import ApiService from './common/general-api'
import VueI18n from 'vue-i18n'
import SocketService from './common/general-socket'

// Sentry: import bug tracking
import * as Sentry from '@sentry/vue'

// import global components
import sidebar from '@/components/sidebar.vue'
import notification from '@/components/module.glob.notification.vue'
import shortcuts from '@/components/module.glob.shortcuts.vue'
import loader from '@/components/module.glob.loader.vue'
import breadcrumbs from '@/components/module.glob.breadcrumbs.vue'

// import translations
import messages from '@/i18n/messages'

// load global components
Vue.component('sidebar', sidebar);
Vue.component('notification', notification);
Vue.component('shortcuts', shortcuts);
Vue.component('loader', loader);
Vue.component('breadcrumbs', breadcrumbs);

// user translation
Vue.use(VueI18n);

// Vue settings
Vue.config.ignoredElements = ['ion-icon'];

// load active product if we are not on production
if( process.env.NODE_ENV !== 'development' ) {
    // Sentry make availible globally => this.$sentry
    Vue.prototype.$sentry = Sentry;
    try {
    // Sentry: do bug tracking
    Sentry.init({
        Vue,
        dsn: "https://61c4649e22b90acc14a33ab43ad61623@ingest.sleekplan.com/4",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "api.sleekplan.com", /^\//],
            }),
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                maskAllInputs: true,
                blockAllMedia: false
            }),
        ],
        environment: process.env.NODE_ENV,
        release: 'app-frontend@' + process.env.VUE_APP_VERSION,
        autoSessionTracking: true,
        sampleRate: 0.5,
        // ! Recommend adjusting this value in production => tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring
        tracesSampleRate: 0.2,
        // Capture Replay for 10% of all sessions,
        // plus for 80% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.8,
        // set initial scope
        initialScope: {
            user: { 
                id: (( store && store.getters && store.getters.user_data ) ? store.getters.user_data.ID : null),
                email: (( store && store.getters && store.getters.user_data ) ? store.getters.user_data.user_mail : null)
            },
        },
    });
    } catch (error) {}
    // production tips
    Vue.config.productionTip = false;
    // production devtools
    Vue.config.devtools = false;
    // hide errors
    Vue.config.errorHandler = function() {}
    // hide warnings
    Vue.config.warnHandler = function() {}
    // hide console
    console.log     = function () {};
    console.info    = function () {};
    console.error   = function () {};
}
  
// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'en', // set default locale
    messages, // set locale messages
});

// init 
ApiService.init();

// add socketService to Vue
Vue.prototype.$socket = SocketService;

// redirect router if not logged in
router.beforeEach((to, from, next) => {
	if ( to.matched.some(record => record.meta.requiresAuth) ) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if ( ! store.getters.user_is_loggedin ) {
            // redirect to login
			next({ name: 'login', query: { redirect: encodeURI(location.href) } });
		} else {
            // go to wherever I'm going
			next();
		}
	} else if( to.matched.some(record => record.meta.isAuth) ) {
        // this route requires non auth, check if logged in
        // if is loggedin, redirect to home page.
        if ( store.getters.user_is_loggedin ) {
            // redirect to home
			next({ name: 'home' });
		} else {
            // go to wherever I'm going
			next();
		}
	} else {
        // does not require auth, make sure to always call next()!
		next();
    }
});

// initialisize Vue
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

// load system assets
require('./assets/css/flexboxgrid.css');
require('./assets/css/system.css');
require('./assets/css/system-style.css');
require('./assets/css/system-components.css');
require('./assets/css/system-elements.css');
require('./assets/css/system-layout.css');
require('./assets/css/system-responsive.css');

// load module script
var fileref_module = document.createElement('script');
    fileref_module.setAttribute('type','module');
    fileref_module.setAttribute('src', 'https://storage.sleekplan.com/static/ionicons/dist/ionicons/ionicons.esm.js');

// load nomodule script
var fileref_nomodule = document.createElement('script');
    fileref_nomodule.setAttribute('nomodule','');
    fileref_nomodule.setAttribute('src', 'https://storage.sleekplan.com/static/ionicons/dist/ionicons/ionicons.js');

// add script to head
document.getElementsByTagName("head")[0].appendChild(fileref_module);
document.getElementsByTagName("head")[0].appendChild(fileref_nomodule);