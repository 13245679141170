import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    user_notifications_items: false,
    user_notifications_query: {},
    user_notifications_count: 0,
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set notifications
    USER_NOTIFICATIONS_SET( state, data ) { state.user_notifications_items = data; },

    // Set notification query
    USER_NOTIFICATIONS_SET_QUERY( state, data ) { state.user_notifications_query = data; },

    // Set notification count
    USER_NOTIFICATIONS_SET_COUNT( state, data ) { state.user_notifications_count = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Query notifications
	 * @param {*} query
	 */
    user_notifications_query( context, query ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 
            
            // load data via API
            ApiService.query('user/' + context.getters.user_data.ID + '/notifications', query, function( data ) {

                // load new product into our product store
                context.dispatch( 'user_notifications_add', data.items );

                // load new product into our product store
                context.dispatch( 'user_notifications_add_query', {has_more: data.has_more, page: data.page} );

                // set success
                return resolve(data);

            }, function( data ) {

                // load new product into our product store
                context.dispatch( 'user_notifications_add', data.items );

                // load new product into our product store
                context.dispatch( 'user_notifications_add_query', {has_more: data.has_more, page: data.page} );

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Count notifications
	 * @param {*} query
	 */
    user_notifications_count( context ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 
            
            // load data via API
            ApiService.get('user/' + context.getters.user_data.ID + '/notifications', 'count', function( data ) {

                // set feedback query
                context.commit('USER_NOTIFICATIONS_SET_COUNT', data.notifications); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Set notification read status
	 * @param {*} query
	 */
    user_notifications_read( context, notifications_id ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 

            // load data via API
            ApiService.put('user/' + context.getters.user_data.ID + '/notifications/read', {notification_id: notifications_id}, function( data ) {

                // load current notifications items
                var notifications_items = Object.assign({}, context.getters.user_notifications_items);
                // check if we need to set all items to read
                if( notifications_id == 'all' ) {
                    // loop t. all notification items
                    for (const [key, value] of Object.entries(notifications_items)) {
                        // set notification items read status for single item
                        notifications_items[key].readit = true;
                    }
                } else {
                    // set notification items read status for single item
                    notifications_items[notifications_id].readit = true;
                }
                // set feedback items
                context.commit('USER_NOTIFICATIONS_SET', notifications_items); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });

        });
        
    },

    /**
	 * Reset notifications
	 * @param {*} query
	 */
    user_notifications_reset( context ) {

        // reset notification counter
        context.commit('USER_NOTIFICATIONS_SET_COUNT', 0); 

        // load current notifications items
        var notifications_items = Object.assign({}, context.getters.user_notifications_items);
        // loop t. all notification items
        for (const [key, value] of Object.entries(notifications_items)) {
            // set notification items seen status
            notifications_items[key].seenit = true;
        }
        // set notification items
        context.commit('USER_NOTIFICATIONS_SET', notifications_items); 

    },

    /**
	 * Add notification items to store
	 * @param {*} context 
	 */
	user_notifications_add( context, items ) {
        
        // load current notifications items
        var notifications_items = Object.assign({}, context.getters.user_notifications_items);

        // for items object
        if( items ) {
            // add new item(s)
            if(notifications_items) Object.assign(notifications_items, items);
            else notifications_items = items;
        }
        
        // set feedback items
        context.commit('USER_NOTIFICATIONS_SET', notifications_items); 

    },

    /**
	 * Add query to store
	 * @param {*} context 
	 */
	user_notifications_add_query( context, query ) {

        // load current feedback query
        var notifications_query = Object.assign({}, context.getters.user_notifications_query);

        // add new item(s)
        Object.assign(notifications_query, query);

        // set feedback query
        context.commit('USER_NOTIFICATIONS_SET_QUERY', notifications_query); 

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get feedback notificationss
	user_notifications_items(state) {
		return state.user_notifications_items;
    },
    
    // get feedback notificationss
	user_notifications_query(state) {
		return state.user_notifications_query;
    },
    
    // get notifications count
    user_notifications_count(state) {
        return state.user_notifications_count;
    }
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};