import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    changelog_items: false,
    changelog_query: {},
    changelog_types: false,
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set active product
    CHANGELOG_SET( state, data ) { state.changelog_items = data; },

    // Set feedback query
    CHANGELOG_SET_QUERY( state, data ) { state.changelog_query = data; },

    // Set feedback types
    CHANGELOG_SET_TYPES( state, data ) { state.changelog_types = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Query changelogs
	 * @param {*} query
	 */
    changelog_query( context, query ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 
            
            // load data via API
            ApiService.query('feedback/' + context.getters.product_active_id + '/changelogs', query, function( data ) {

                // load new product into our product store
                context.dispatch( 'changelog_add', {items: data.items} );

                // load new product into our product store
                context.dispatch( 'changelog_add_query', {has_more: data.has_more, page: data.page} );

                // set success
                return resolve(data);

            }, function( data ) {

                // load new product into our product store
                context.dispatch( 'changelog_add', {items: data.items} );

                // load new product into our product store
                context.dispatch( 'changelog_add_query', {has_more: data.has_more, page: data.page} );

                // set rejection
                return reject(data);

            });

        });
        
    },

	/**
	 * Load / refresh changelog
	 * @param {*} {changelog_id, force}
	 */
    changelog_load( context, {changelog_id, force} ) {

        // call api to get single changelog item
		return new Promise( function(resolve, reject) { 

            // check if item exists
            if( state.changelog_items[changelog_id] && force !== true ) {

                // set success
                return resolve( state.changelog_items[changelog_id] );

            // if changelog item doesnt exist try to load
            } else {

                // load data via API
                ApiService.get('feedback/' + context.getters.product_active_id + '/changelog', changelog_id, function( data ) {

                    // load new product into our product store
                    context.dispatch( 'changelog_add', {item: data} );

                    // set success
                    return resolve(data);

                }, function( data ) {

                    // set rejection
                    return reject(data);

                });
            
            }

        });
        
    },
    
    /**
	 * Create new changelog
	 * @param {*} context 
	 */
    changelog_create( context, {changelog_data, reload = false} ) {

        // call api to create changelog
		return new Promise( function(resolve, reject) { 

            // send data
            ApiService.post('feedback/' + context.getters.product_active_id + '/changelog', changelog_data, function( data ) {

                // if we dont wait for the reload
                if( reload === false ) {
                    // set success
                    return resolve(data);
                }

                // get single changelog item
                context.dispatch( 'changelog_load', {changelog_id: data.changelog_id, force: true} )
                .then((data) => {
                    // if we do wait for the reload
                    if( reload === true ) {
                        // set success
                        return resolve(data);
                    }
                })
                .catch(e => {
                    // if we do wait for the reload
                    if( reload === true ) {
                        // set rejection
                        return reject(e);
                    }
                });

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },
    
    /**
	 * Update changelog
	 * @param {*} context 
	 */
	changelog_update( context, {changelog_id, changelog_data, reload = false} ) {

        // call api to update changelog
		return new Promise( function(resolve, reject) { 

            // update changelog via api
            ApiService.update('feedback/' + context.getters.product_active_id, 'changelog/' + changelog_id, changelog_data, function( data ) {

                // if we dont wait for the reload
                if( reload === false ) {
                    // set success
                    return resolve(data);
                }

                // get single changelog item
                context.dispatch( 'changelog_load', {changelog_id: data.changelog_id, force: true} )
                .then((data) => {
                    // if we do wait for the reload
                    if( reload === true ) {
                        // set success
                        return resolve(data);
                    }
                })
                .catch(e => {
                    // if we do wait for the reload
                    if( reload === true ) {
                        // set rejection
                        return reject(e);
                    }
                });

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Delete changelog
	 * @param {*} context 
	 */
	changelog_delete( context, changelog_id ) {

        // call api to update feedback
		return new Promise( function(resolve, reject) { 

            // delete changelog via api
            ApiService.delete('feedback/' + context.getters.product_active_id + '/changelog/' + changelog_id, function( data ) {

                // remove single product
                context.dispatch( 'changelog_remove', data.changelog_id );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Upload changelog image
	 * @param {*} context 
	 */
	changelog_upload( context, {name, file, progress = ()=>{}} ) {

        // call api to update changelog
		return new Promise( function(resolve, reject) { 

            // update changelog via api
            ApiService.upload('feedback/' + context.getters.product_active_id + '/changelog/upload', name, file, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            }, function( percent ) {

                // set rejection
                return progress(percent);

            });
            
        });

    },

    /**
	 * Query changelogs
	 * @param {*} query
	 */
    changelog_types( context ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 
            
            // load data via API
            ApiService.get('feedback/' + context.getters.product_active_id + '/types', 'changelog', function( data ) {

                // set feedback query
                context.commit('CHANGELOG_SET_TYPES', data); 
                
                // set success
                return resolve(data);

            }, function( data ) {

                // set feedback query
                context.commit('CHANGELOG_SET_TYPES', {}); 

                // set success
                return reject(data);

            });

        });
        
    },

    /**
	 * Add changelog item(s) to store
	 * @param {*} context 
	 */
	changelog_add( context, {items, item} ) {

        // load current changelog items
        var changelog_items = Object.assign({}, context.getters.changelog_items);

        // for items object
        if( items ) {
            // add new item(s)
            if(changelog_items) Object.assign(changelog_items, items);
            else changelog_items = items;
        }

        // for single item
        if( item ) {
            // add new item(s)
            changelog_items[item.changelog_id] = item;
        }

        // set feedback items
        context.commit('CHANGELOG_SET', changelog_items); 

    },

    /**
	 * Add query to store
	 * @param {*} context 
	 */
	changelog_add_query( context, query ) {

        // load current feedback query
        var changelog_query = Object.assign({}, context.getters.changelog_query);

        // add new item(s)
        Object.assign(changelog_query, query);

        // set feedback query
        context.commit('CHANGELOG_SET_QUERY', changelog_query); 

    },

    /**
	 * Remove changelog item(s) to store
	 * @param {*} context 
	 */
	changelog_remove( context, item_id ) {

        // load current changelog items
        var changelog_items = Object.assign({}, context.getters.changelog_items);
        // remove hcangelog item
        delete changelog_items[item_id];

        // set feedback items
        context.commit('CHANGELOG_SET', changelog_items); 

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get changelog items
	changelog_items(state) {
		return state.changelog_items;
    },
    
    // get changelog query
	changelog_query(state) {
		return state.changelog_query;
	},

    // get changelog types
    changelog_types(state) {
        return state.changelog_types;
    }
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};