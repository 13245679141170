import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    subscription: false,
    customer: false,
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set active product
    SUBSCRIPTION_SET( state, data ) { state.subscription = data; },

    // Set feedback query
    CUSTOMER_SET( state, data ) { state.customer = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Get all plans
	 * @param {*} query
	 */
    subscription_plans( context ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.get('subscription', 'plans', function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Get all addons
	 * @param {*} query
	 */
    subscription_addons( context ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.get('subscription', 'addons', function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Get subscription
	 * @param {*} context 
	 */
    subscription_get( context ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.get('subscription/product', context.getters.product_active_id, function( data ) {

                // set data
				context.commit('SUBSCRIPTION_SET', data); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Update subscription
	 * @param {*} context 
	 */
    subscription_update( context, subscription_data ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.update('subscription/product', context.getters.product_active_id, subscription_data, function( data ) {

                // set data
				context.commit('SUBSCRIPTION_SET', data); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Get customer
	 * @param {*} context 
	 */
    customer_get( context ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.get('subscription/customer', context.getters.product_active_id, function( data ) {

                // set data
				context.commit('CUSTOMER_SET', data); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Update customer
	 * @param {*} context 
	 */
    customer_update( context, customer_data ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.update('subscription/customer', context.getters.product_active_id, customer_data, function( data ) {

                // set data
				context.commit('CUSTOMER_SET', data); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Get coupon
	 * @param {*} context 
	 */
    coupon_get( context, coupon ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.get('subscription/coupon', coupon, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Set addons
	 * @param {*} query
	 */
    subscription_addons_set( context, addon_data ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.post('subscription/product/' + context.getters.product_active_id + '/addons/set', addon_data, function( data ) {

                // set data
				context.commit('SUBSCRIPTION_SET', data); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Get addons preview
	 * @param {*} query
	 */
    subscription_addons_preview( context, addon_data ) {

        // call api to get plans
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.post('subscription/product/' + context.getters.product_active_id + '/addons/preview', addon_data, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get subscription
	subscription(state) {
		return state.subscription;
    },

    // get customer
	customer(state) {
		return state.customer;
    }
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};