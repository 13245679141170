<template>
    <div class="box page-area onboarder" :class="{active: onboarder_active}" v-if="onboard_data.active === true">
        <div class="header" v-on:click="onboarder_active = !onboarder_active">
            <h3 v-if="onboard_data.progress < 90">Getting Started with Sleekplan</h3>
            <h3 v-else-if="onboard_data.progress >= 90">Well done! You're all set..</h3>
            <h3 v-else>Loading...</h3>
            <div class="percent">{{onboard_data.progress}}%</div>
            <div class="process"><div :style="{width: onboard_data.progress + '%'}"></div></div>
        </div>
        <div class="actions">
            <div v-for="(step, index) in onboard_data.steps" v-bind:key="index" :class="{'action': (step.header !== true), 'actions-head': (step.header == true), active: (onboarder_action === index), completed: (step.status == 'completed')}" v-on:click="onboarder_action = index" v-if="step.visible == true">
                <div v-if="step.header == true">{{step.title}}</div>
                <div class="description" v-else>
                    <div class="title">
                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                        <span>{{step.title}}</span>
                    </div>
                    <div class="text">
                        {{step.text}}
                        <div class="cta">
                            <span v-for="(action, index) in step.actions" v-bind:key="index">
                                <a v-if="action.type == 'link'" :href="action.src" :target="action.target" class="btn tiny" :class="{blue: (action.highlight == true)}">{{action.text}}</a>
                                <a v-if="action.type == 'script'" :onclick="action.src" class="btn tiny" :class="{blue: (action.highlight == true)}">{{action.text}}</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="status" v-if="step.counter">
                    <div class="counter">{{step.counter.done}}/{{step.counter.todo}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
		return {
            onboarder_active: false,
            onboarder_action: false,
        }

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        onboard_data() {
            return this.$store.getters.onboard;
        },
    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Load onbaording
         */
        load_onboarding() {

            // set new status
            this.$store.dispatch( 'onboard_load' ).then((data) => {}).catch(e => {});
            
        },

    },


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted () {

        // load onbaording
        this.load_onboarding();

    }


}
</script>
