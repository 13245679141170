/**
 * **************************
 * Helper:
 * Date manager
 * **************************
 */

const DateHelper = {

	// initally load ApiService
	convert( date ) {

        if( date ) {
            return date.replace(/-/g, "/");
        } else {
            return date;
        }
		

    },

}
    
export default DateHelper;