import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    product_widget: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set page data
    PRODUCT_WIDGET_SET( state, data ) { state.product_widget = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get product widget settings
	 * @param {*} context 
	 */
	product_widget_get( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id, 'widget', function( data ) {

				// set data
				context.commit('PRODUCT_WIDGET_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Update product widget settings
	 * @param {*} context 
	 * @param {object} widget_data 
	 */
	product_widget_update( context, widget_data ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/widget', widget_data, function( data ) {

				// set data
				context.commit('PRODUCT_WIDGET_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// product page data
	product_widget(state) {
		return state.product_widget;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};