<template>
    <div class="app-modal" :class="custom_class">

        <!-- Appending to body -->
        <div v-if="append_to_body === true">
            <Teleport to="body">
                <div class="app-modal" :class="custom_class">
                    <div v-if="showModal" class="app-modal-area">
                        <div class="app-modal-inner">
                            <div v-on:click.stop.prevent="hide_modal()" class="app-modal-overlay"></div>
                            <div class="app-modal-box">
                                <div class="app-modal-close">
                                    <ion-icon name="ios-close" v-on:click.stop.prevent="hide_modal()"></ion-icon>
                                </div>
                                <div class="app-modal-content" ref="content">
                                    <div class="app-modal-head">
                                        <slot name="header">Headline</slot>
                                    </div>
                                    <div class="app-modal-context">
                                        <slot name="content"></slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Teleport>
        </div>

        <!-- Default inline -->
        <div v-else>
            <div v-if="showModal" class="app-modal-area">
                <div class="app-modal-inner">
                    <div v-on:click.stop.prevent="hide_modal()" class="app-modal-overlay"></div>
                    <div class="app-modal-box">
                        <div class="app-modal-close">
                            <ion-icon name="ios-close" v-on:click.stop.prevent="hide_modal()"></ion-icon>
                        </div>
                        <div class="app-modal-content" ref="content">
                            <div class="app-modal-head">
                                <slot name="header">Headline</slot>
                            </div>
                            <div class="app-modal-context">
                                <slot name="content"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Slot for button -->
        <div v-on:click.stop.prevent="show_modal()" class="app-modal-action" v-if="hide_button !== true">
            <slot name="reference"> Open modal </slot>
        </div>
    </div>
</template>

<script>
import Teleport from '@/components/module.loc.teleport';

export default {

    name: 'modal',

    props: ['hide_button', 'append_body', 'class_name'],


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        return { 
            showModal: false, 
            append_to_body: ((this.append_body === true) ? true : false),
            custom_class: ((this.class_name) ? this.class_name : false),
        };
    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Show modal
         */
        show_modal() {
            // show modal
            this.showModal = true;
            // add body style
            document.body.style.overflow = "hidden";
            // wait for the modal to open
            this.$nextTick(() => {
                // set focus on the button inside content section
                this.$refs.content.querySelector('button').focus();
            });
        },

        /**
         *  Hide modal
         */
        hide_modal() {
            // hide modal
            this.showModal = false;
            // remove body style
            document.body.style.overflow = null;
            // trigger close
            this.$emit('close', '');
        }

    },


    /** ----------------------------------------------------
     * View => On Destroyed
     ----------------------------------------------------- */
    destroyed() {

        // parallax effect stop
        this.hide_modal();

    },


    /** ----------------------------------------------------
     * View => Registred components
     ----------------------------------------------------- */
    components: {
        Teleport
    },

}
</script>
