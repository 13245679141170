import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	app_settings: JwtService.getData('settings')
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Add notification
	APP_SETTINGS_UPDATE( state, settings ) { state.app_settings = settings; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Update settings
	 * @param {*} context 
	 * @param {object} notification_data 
	 */
	app_settings_update( context, {settings_key, settings_data} ) {

        // load current settings
        var settings = Object.assign({}, state.app_settings, {[settings_key]: settings_data});

		// save settings
		JwtService.setData(settings, 'settings');

        // commit changes
        context.commit('APP_SETTINGS_UPDATE', settings);

	},
	
	/**
	 * Delete settings
	 * @param {*} context 
	 * @param {object} notification_data 
	 */
	app_settings_delete( context, settings_key ) {

        // load current settings
        var settings = Object.assign({}, state.app_settings );

		// delete key
		delete settings[settings_key];

		// save settings
		JwtService.setData(settings, 'settings');

        // commit changes
        context.commit('APP_SETTINGS_UPDATE', settings);

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get noticiations
	app_settings(state) {
		return state.app_settings;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};