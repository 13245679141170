import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	satisfaction: false,
	satisfaction_keys: false,
	satisfaction_key: false,
	satisfaction_feed: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set satisfaction
	SATISFACTION_SET( state, data ) { state.satisfaction = data; },
	
	// Set satisfaction key list
	SATISFACTION_SET_KEYS( state, data ) { state.satisfaction_keys = data; },
	
	// Set satisfaction key
	SATISFACTION_SET_KEY( state, data ) { state.satisfaction_key = data; },
	
	// Set satisfaction feed
    SATISFACTION_SET_FEED( state, data ) { state.satisfaction_feed = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get Satisfaction stats
	 * @param {*} context 
	 * @param {object} query 
	 */
	satisfaction_load( context, {query, key} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/satisfaction/key/' + key + '/list', query, function( data ) {

				// prepare data
				let merged_data = Object.assign( {}, state.satisfaction, {[key]: data} )

				// set data
				context.commit('SATISFACTION_SET', merged_data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},
	
	/**
	 * Get Satisfaction keys
	 * @param {*} context 
	 * @param {object} query 
	 */
	satisfaction_keys( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/satisfaction', 'keys', function( data ) {

				// set data
				context.commit('SATISFACTION_SET_KEYS', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},
	
	/**
	 * Get Satisfaction key
	 * @param {*} context 
	 * @param {object} query 
	 */
	satisfaction_key( context, key ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id + '/satisfaction/key', key, function( data ) {

				// prepare data
				let merged_data = Object.assign( {}, state.satisfaction_key, {[key]: data} )

				// set data
				context.commit('SATISFACTION_SET_KEY', merged_data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},
	
	/**
	 * Delete Satisfaction key
	 * @param {*} context 
	 * @param {object} query 
	 */
	satisfaction_key_delete( context, key ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/satisfaction/key/' + key, function( data ) {

				// empty satisfaction keys
				context.commit('SATISFACTION_SET_KEYS', false); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Get Satisfaction feed
	 * @param {*} context 
	 * @param {string} key 
	 */
	satisfaction_feed( context, {query, key} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// check if page is 0
			if(query.page === 0) { 
				// reset data
				var current_feed_data = state.satisfaction_feed;
				delete current_feed_data[key];
				context.commit('SATISFACTION_SET_FEED', current_feed_data); 
			}

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/satisfaction/key/' + key + '/feed', query, function( data ) {

				// -------- UPDATE feed list --------- //
				// prepare data
				let satisfaction_feed = Object.assign( {}, state.satisfaction_feed );

				// create feed key if not exist
				if( ! satisfaction_feed[key] ) {
					satisfaction_feed[key] = {items: new Array(), page: 0, has_more: true};
				}

				// merge feed array
				satisfaction_feed[key].items = [...satisfaction_feed[key].items, ...data];
				// set current page
				satisfaction_feed[key].page = query.page;

				// check if we have more items -> default 10 per page
				if( data.length < ((query.per_page) ? query.per_page : 10) ) {
					satisfaction_feed[key].has_more = false;
				}

				// set data
				context.commit('SATISFACTION_SET_FEED', satisfaction_feed); 
				// -------- /UPDATE feed list --------- //

				// set success
				return resolve(data);

			}, function( data ) {

				// -------- UPDATE feed list --------- //
				// prepare data
				let satisfaction_feed = Object.assign( {}, state.satisfaction_feed );

				// create feed key if not exist
				if( ! satisfaction_feed[key] ) {
					satisfaction_feed[key] = {items: new Array(), page: 0, has_more: true};
				}

				// set current page
				satisfaction_feed[key].has_more = false;

				// set data
				context.commit('SATISFACTION_SET_FEED', satisfaction_feed); 
				// -------- /UPDATE feed list --------- //

				// set rejection
				return reject(data);

			});

		});

	},

	/**
	 * Delete satisfaction response item
	 * @param {*} context 
	 */
    satisfaction_response_delete( context, satisfaction_id ) {

        // call api to satsifaction response
		return new Promise( function(resolve, reject) { 

            // delete satsifaction response by satsifaction_id via api
            ApiService.delete('product/' + context.getters.product_active_id + '/satisfaction/response/' + satisfaction_id, function( data ) {

				// Delete from store ----------
				// load current satsifaction feed from store
				var satisfaction_feed = Object.assign({}, context.getters.satisfaction_feed);
				
				// loop trhough all satisfaction keys (i.e default, all, ...)
				for (const [key, value] of Object.entries(satisfaction_feed)) {
					// check if satsifaction key has items
					if( satisfaction_feed[key].items && satisfaction_feed[key].items.length > 0 ) {
						// remove all items that have the deleted satisfaction_id set
						satisfaction_feed[key].items = satisfaction_feed[key].items.filter(function( response_obj ) {
							return response_obj.satisfaction_id !== satisfaction_id;
						});
					}
				}

				// set new satsifaction feed
				context.commit('SATISFACTION_SET_FEED', satisfaction_feed); 
				// End delete from store ----------

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// satisfaction stats
	satisfaction(state) {
		return state.satisfaction;
	},
	
	// satisfaction keys
	satisfaction_keys(state) {
		return state.satisfaction_keys;
	},
	
	// satisfaction keys
	satisfaction_key(state) {
		return state.satisfaction_key;
	},
	
	// satisfaction feed
	satisfaction_feed(state) {
		return state.satisfaction_feed;
    },

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};