import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	product_analytics: false,
	product_analytics_feed: {},
	product_analytics_stats: {},
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set satisfaction
	PRODUCT_ANALYTICS_SET( state, data ) { state.product_analytics = data; },

	// Set product analytics feed
    PRODUCT_ANALYTICS_SET_FEED( state, data ) { state.product_analytics_feed = data; },

	// Set product analytics stats
    PRODUCT_ANALYTICS_SET_STATS( state, data ) { state.product_analytics_stats = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Load pageviews
	 * @param {*} context 
	 * @param {object} query 
	 */
    product_analytics_pageviews_load( context, query ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/analytics/pageviews', (( query ) ? query : {}), function( data ) {

				// prepare data
				let merged_data = Object.assign( {}, state.product_analytics, ((query && query.key) ? {['pageviews_' + query.key]: data} : {['pageviews']: data} ) );

				// set data
				context.commit('PRODUCT_ANALYTICS_SET', merged_data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},

	/**
	 * Get Analytics Feed
	 * @param {*} context 
	 */
	product_analytics_get_feed( context, query ) {

		// set query name
		var _query = Object.assign({}, query);
		delete _query.page;
		var filter_name = JSON.stringify(_query);

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/analytics/feed', query, function( data ) {

				// load current analytics feed
				var analytics_feed = Object.assign({}, context.getters.product_analytics_feed);

				// if key exist in analytics feed
				if( [filter_name] in analytics_feed && query.page !== 0 && analytics_feed[filter_name].page <= query.page ) {

					// add new item(s)
					analytics_feed[filter_name].results.push(...data);

					// set data
					analytics_feed[filter_name].page 		= query.page
					analytics_feed[filter_name].has_more 	= ((data.length >= 50) ? true : false )
				
				// if key does not exist in product feed
				} else {

					// add new item(s)
					Object.assign(analytics_feed, {[filter_name]: {results: data, page: query.page, has_more: ((data.length >= 50) ? true : false )}});
				
				}

				// set data
				context.commit('PRODUCT_ANALYTICS_SET_FEED', analytics_feed); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Get Analytics Stats
	 * @param {*} context 
	 */
	product_analytics_get_stats( context, query ) {

		// set query name
		var _query = Object.assign({}, query);
		delete _query.page;
		var filter_name = JSON.stringify(_query);

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/analytics/stats', query, function( data ) {

				// load current analytics stats
				var product_analytics_stats = Object.assign({}, context.getters.product_analytics_stats);

				// add new item(s)
				Object.assign(product_analytics_stats, {[filter_name]: data});

				// set data
				context.commit('PRODUCT_ANALYTICS_SET_STATS', product_analytics_stats); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// product analytics getter
	product_analytics(state) {
		return state.product_analytics;
	},

	// product analytics feed
	product_analytics_feed(state) {
		return state.product_analytics_feed;
    },

	// product analytics stats
	product_analytics_stats(state) {
		return state.product_analytics_stats;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};