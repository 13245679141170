import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    product_email_data: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set domain data
    PRODUCT_EMAIL_SET( state, email_data ) { state.product_email_data = email_data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get Custom Email
	 * @param {*} context 
	 */
	product_email_get( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id, 'email', function( data ) {

				// set data
				context.commit('PRODUCT_EMAIL_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Set Custom Email
	 * @param {*} context 
	 * @param {object} email_address 
	 */
    product_email_set( context, email_address ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/email', {email: email_address}, function( data ) {

				// set data
				context.commit('PRODUCT_EMAIL_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Send Verification Email
	 * @param {*} context 
	 */
    product_email_verification( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/email/verification', {}, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Activate Custom Email
	 * @param {*} context 
	 */
    product_email_activate( context, verification ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/email/activate', {verification: verification}, function( data ) {

				// set data
				context.commit('PRODUCT_EMAIL_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Remove Custom Email
	 * @param {*} context 
	 */
    product_email_remove( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/email', function( data ) {

				// set data
				context.commit('PRODUCT_EMAIL_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// product domain data
	product_email_data(state) {
		return state.product_email_data;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};