import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
    stats_general: false
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
    
    // Set general stats
    STATS_GENERAL_SET( state, data ) { state.stats_general = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Get general stats
	 * @param {*} context 
	 */
	stats_general_load( context, range = 30 ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/stats/general', {range: range}, function( data ) {

				// get current stats
				var merged_stats = Object.assign({}, state.stats_general);

				// set stats range
				merged_stats[range] = data;

				// set data
				context.commit('STATS_GENERAL_SET', merged_stats); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

    // general stats
    stats_general(state) {
		return state.stats_general;
    },

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};