import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    feedback_query: {},
    feedback_semaphore: {},
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set feedback query
    FEEDBACK_SET_QUERY( state, data ) { state.feedback_query = data; },

    // Set feedback query
    FEEDBACK_SET_SEMAPHORE( state, data ) { state.feedback_semaphore = Object.assign({}, state.feedback_semaphore, data) },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Load query based on params
	 * @param {*} context 
	 * @param {object} params 
	 */
	feedback_query( context, params ) {
		
		// call api to get feedback items
		return new Promise( function(resolve, reject) { 

            // set query name
            var query_name = JSON.stringify(params.query);
            
            // check if query is currently running
            if( state.feedback_semaphore[query_name] == true ) {
                // if so return false
                return false;
            }

            // set semaphore so we dont run twice into loading process
            context.commit('FEEDBACK_SET_SEMAPHORE', {[query_name]: true});

			// load data via API
			ApiService.query('feedback/' + context.getters.product_active_id + '/items', Object.assign({}, params.query, {page: params.page}), function( data ) {

                // load existing feedback query
                let feedback_results = Object.assign({}, context.getters.feedback_query);

                // set order and items
                let items = data.items;
                let order = data.order;

                // build new results
                feedback_results[query_name] = {
                    results: ((feedback_results[query_name] && Array.isArray(feedback_results[query_name].results)) ? feedback_results[query_name].results.concat(order) : order ),
                    page: params.page,
                    has_more: data.has_more,
                    query: params.query,
                    time: ((feedback_results[query_name] && feedback_results[query_name].time) ? feedback_results[query_name].time : new Date()),
                    refresh: ((feedback_results[query_name] && feedback_results[query_name].refresh) ? feedback_results[query_name].refresh : false),
                };

                // load new product into our product store
                context.dispatch( 'feedback_item_add', items );

                // set query data
                context.commit('FEEDBACK_SET_QUERY', feedback_results);

                // free semaphore
                context.commit('FEEDBACK_SET_SEMAPHORE', {[query_name]: false});
                
				// set success
				return resolve(feedback_results[query_name]);

			}, function( data ) {

                // load existing feedback query
                let feedback_results = Object.assign({}, context.getters.feedback_query);

                // if we dont have results
                if( ! feedback_results[query_name] ) {
                    // create a default object
                    feedback_results[query_name] = {
                        results: {},
                        page: params.page,
                        has_more: false,
                        query: params.query,
                        time: new Date()
                    };
                } else {
                    // set more to false
                    feedback_results[query_name].has_more = false;
                }

                // set query data
                context.commit('FEEDBACK_SET_QUERY', feedback_results);

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Refresh query based on params
	 * @param {*} context 
	 * @param {object} params 
	 */
	feedback_query_refresh( context, params ) {

        // call api to get feedback items
		return new Promise( function() { 

            // set query name
            var query_name = JSON.stringify(params.query);
            
            // check if semaphore is locked
            if( state.feedback_semaphore[query_name] == true ) {
                // free semaphore
                context.commit('FEEDBACK_SET_SEMAPHORE', {[query_name]: false});
            }

            // load existing feedback query
            var feedback_results = Object.assign({}, context.getters.feedback_query);

            // remove query
            delete feedback_results[query_name]

            // set new query data
            context.commit('FEEDBACK_SET_QUERY', feedback_results);

            // load feedback query
			context.dispatch( 'feedback_query', {query: params.query, page: 0 } );

        });

    },

    /**
	 * Clear query based on query_params
	 * @param {*} context 
	 * @param {object} query_params 
	 */
	feedback_query_clear( context, {key, value} ) {

        // call api to get feedback items
		return new Promise( function() { 

            // check if key or value is missing
            if(!key || !value)
                return false;

            // load existing feedback query
            var feedback_results = Object.assign({}, context.getters.feedback_query);

            // loop through all objects
            for (const query_json in feedback_results) {

                // convert json Key to Object
                var query_object = JSON.parse(query_json);

                // check if we have an object key
                if( ! query_object[key] ) {
                    // skip if we have no key
                    continue;
                }

                // check ? String AND exlude in value -> must be Object
                if( typeof value === "object" && typeof query_object[key] === "string" && value.exclude && query_object[key] !== value.exclude ) {
                    // reload results
                    context.dispatch( 'feedback_query_refresh', {query: query_object} );
                // check ? String
                } else if( typeof query_object[key] === "string" && query_object[key] == value ) {
                    // reload results
                    context.dispatch( 'feedback_query_refresh', {query: query_object} );
                // check ? Array
                } else if( typeof query_object[key] === "object" && query_object[key].includes(value) ) {
                    // reload results
                    context.dispatch( 'feedback_query_refresh', {query: query_object} );
                }
                
            }

        });

    },
    
    /**
	 * Load query raw data
	 * @param {*} context 
	 * @param {object} query 
	 */
	feedback_query_raw( context, query ) {
		
		// call api to get feedback items
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('feedback/' + context.getters.product_active_id + '/items', query, function( data ) {

                // load new product into our product store
                context.dispatch( 'feedback_item_add', data.items );
                
				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},

    /**
	 * Check query for new items
	 * @param {*} context 
	 * @param {object} params 
	 */
	feedback_query_check( context, params ) {
		
		// call api to get feedback items
		return new Promise( function(resolve, reject) { 

            // set query name
            var query_name = JSON.stringify(params.query);

            // load existing feedback query
            var feedback_results = Object.assign({}, context.getters.feedback_query);
            
            // check if query exist
            if( ! feedback_results[query_name] ) {
                return false;
            }

			// load data via API
			ApiService.query('feedback/' + context.getters.product_active_id + '/check_items', Object.assign({}, params.query, {time: feedback_results[query_name].time}), function( data ) {

                // check if we have new items
                if( Boolean(data.has_new) !== true ) {
                    return false;
                }

                // set has new results
                feedback_results[query_name].refresh = {
                    has_new: Boolean(data.has_new),
                    count: Number(data.count)
                };

                // set query data
                context.commit('FEEDBACK_SET_QUERY', feedback_results);
                
				// set success
				return resolve(feedback_results[query_name]);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

    // get feedback query
	feedback_query(state) {
		return state.feedback_query;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};