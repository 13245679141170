import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	promoter: false,
	promoter_feed: {items: new Array(), page: 0, has_more: true},
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set promoter
	PROMOTER_SET( state, data ) { state.promoter = data; },
	
	// Set promoter feed
    PROMOTER_SET_FEED( state, data ) { state.promoter_feed = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get Promoter stats
	 * @param {*} context 
	 * @param {object} query 
	 */
	promoter_load( context, query ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/promoter/list', query, function( data ) {

				// set data
				context.commit('PROMOTER_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

	},

	/**
	 * Get Promoter feed
	 * @param {*} context 
	 * @param {string} key 
	 */
	promoter_feed( context, query ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// check if page is 0
			if(query.page === 0) { 
				// reset data
				context.commit('PROMOTER_SET_FEED', {items: new Array(), page: 0, has_more: true}); 
			}

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/promoter/feed', query, function( data ) {

				// -------- UPDATE feed list --------- //
				// prepare data
				let promoter_feed = Object.assign( {}, state.promoter_feed );

				// merge feed array
				promoter_feed.items = [...promoter_feed.items, ...data];
				// set current page
				promoter_feed.page = query.page;
				
				// check if we have more items -> default 10 per page
				if( data.length < ((query.per_page) ? query.per_page : 10) ) {
					promoter_feed.has_more = false;
				}

				// set data
				context.commit('PROMOTER_SET_FEED', promoter_feed); 
				// -------- /UPDATE feed list --------- //

				// set success
				return resolve(data);

			}, function( data ) {

				// -------- UPDATE feed list --------- //
				// prepare data
				let promoter_feed = Object.assign( {}, state.promoter_feed );

				// set current page
				promoter_feed.has_more = false;

				// set data
				context.commit('PROMOTER_SET_FEED', promoter_feed); 
				// -------- /UPDATE feed list --------- //

				// set rejection
				return reject(data);

			});

		});

	},

	/**
	 * Delete promoter response item
	 * @param {*} context 
	 */
    promoter_response_delete( context, promoter_id ) {

        // call api to promoter response
		return new Promise( function(resolve, reject) { 

            // delete promoter response by promoter_id via api
            ApiService.delete('product/' + context.getters.product_active_id + '/promoter/response/' + promoter_id, function( data ) {

				// Delete from store ----------
				// load current promoter feed from store
				var promoter_feed = Object.assign({}, context.getters.promoter_feed);

				// check if we have promoter items
				if( promoter_feed.items && promoter_feed.items.length > 0 ) {
					// remove all items that have the deleted promoter_id set
					promoter_feed.items = promoter_feed.items.filter(function( response_obj ) {
						return response_obj.promoter_id !== promoter_id;
					});
				}

				// set new promoter feed
				context.commit('PROMOTER_SET_FEED', promoter_feed);
				// End delete from store ----------

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// promoter stats
	promoter(state) {
		return state.promoter;
	},
	
	// promoter keys
	promoter_key(state) {
		return state.promoter_key;
	},
	
	// promoter feed
	promoter_feed(state) {
		return state.promoter_feed;
    },

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};