import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    product_views: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set page data
    PRODUCT_VIEWS_SET( state, data ) { state.product_views = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get views
	 * @param {*} context 
	 */
	product_views_get( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id, 'views', function( data ) {

				// set data
				context.commit('PRODUCT_VIEWS_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Create view
	 * @param {*} context 
	 * @param {object} page_data 
	 */
	product_view_create( context, view_data ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/view', view_data, function( data ) {

				// load new views
                context.dispatch( 'product_views_get' ).then(() => { 
					// set success
					return resolve(data);
				})
				.catch(e => {
					// set success
					return resolve(data);
				});

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Delete view
	 * @param {*} context 
	 * @param {object} page_data 
	 */
	product_view_delete( context, view_id ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/view/' + view_id, function( data ) {

				// load new views
                context.dispatch( 'product_views_get' ).then(() => { 
					// set success
					return resolve(data);
				})
				.catch(e => {
					// set success
					return resolve(data);
				});

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// product page data
	product_views(state) {
		return state.product_views;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};