import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    product_privacy: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set privacy data
    PRODUCT_PRIVACY_SET( state, data ) { state.product_privacy = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get product privacy
	 * @param {*} context 
	 */
	product_privacy_get( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id, 'privacy', function( data ) {

				// set data
				context.commit('PRODUCT_PRIVACY_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Update product page
	 * @param {*} context 
	 * @param {object} page_data 
	 */
    product_privacy_update( context, privacy_data ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.put('product/' + context.getters.product_active_id + '/privacy', privacy_data, function( data ) {

				// set data
				context.commit('PRODUCT_PRIVACY_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// product page data
	product_privacy(state) {
		return state.product_privacy;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};