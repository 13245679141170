<template>
    <modal ref="lmodal" :hide_button="true" :append_body="true" :class_name="'list ' + custom_class" v-on:close="$emit('close', '')">
        <!-- search bar -->
        <div slot="header" class="app-modal-search" v-if="enable_search">
            <input type="text" ref="lsearch" tabindex="-1" autofocus :placeholder="enable_search" v-on:click.stop v-on:keydown="list_navigate" v-on:keyup="list_search" v-model="search.term">
        </div>
        <!-- content -->
        <div slot="content" class="app-modal-list">
            <!-- result area -->
            <div v-if="list_loading === false">
                <!-- if we have items -->
                <div v-if="items !== false">
                    <!-- List items -->
                    <div class="app-modal-list-item" v-for="(item, item_id) in items" v-bind:key="item_id" v-on:click="list_select(item_id, true)" v-on:mouseover="list_navigate_hover(item_id)" :class="{active: (list_current_id == item_id), processing: (action_loading[item_id] == true)}">
                        <div class="icon" v-if="item.img">
                            <img :src="item.img">
                        </div>
                        <div class="data">
                            <div class="main"><b>{{item.key}}</b></div>
                            <div v-if="item.meta" v-for="(meta, key) in item.meta" v-bind:key="key" :class="meta.class">
                                <span v-if="meta.icon" v-html="meta.icon"></span>
                                <span v-if="meta.value">{{ meta.value }}</span>
                            </div>
                        </div>
                        <div class="action" v-if="item.action" v-on:click.stop="list_select(item_id)">
                            <button class="btn slim tiny" :class="item.action.class">
                                <img class="loading" v-if="action_loading[item_id] === true" src="@/assets/media/svg/loading.svg">
                                <span v-else>{{ item.action.name }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- if we have no items and creation is turned on -->
                <div v-else-if="items === false && enable_create">
                    <div class="app-modal-list-item active" v-on:click.stop="list_create">
                        <div class="data">
                            <div class="main"><b>{{search.term}}</b></div>
                            <div class="badge">
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 112v288M400 256H112"/></svg></span>
                                <span>New</span>
                            </div>
                        </div>
                        <div class="action">
                            <button class="btn slim tiny">
                                <img class="loading" v-if="creation_loading === true" src="@/assets/media/svg/loading.svg">
                                <span v-else>{{ enable_create }}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- if we have no items and creation is turned off -->
                <div v-else="items === false">
                    <div class="app-modal-list-item" v-on:click.stop="$emit('create', search.term)">
                        <div class="data">
                            <div class="main">No results</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- on load -->
            <div v-else>
                <loader type="dark" height="50" size="small"/>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/module.loc.modal';

export default {

    name: 'modale-list',

    props: ['items', 'loading', 'enable_search', 'enable_create', 'create_data', 'custom_class'],


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {

        return {

            search: {},
            search_timeout: false,
            search_delay: 500,
            list_current: false,
            list_current_id: false,
            action_loading: {},
            creation_loading: false,

        }

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        list_loading() {
            return ((this.loading) ? this.loading : false );
        }

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  list => open
         */
        list_show() {
            
            // reset search
            this.search = {};
            // trigger search
            this.$emit('search', '');
            // open list modal
            this.$refs.lmodal.show_modal();
            // set 500 ms timeout
            setTimeout(() => {
                // set focus on the search
                this.$refs.lsearch.focus();
            }, 500);

        },

        /**
         *  list => hide
         */
        list_hide() {
            
            // reset search
            this.search = {};
            // open list modal
            this.$refs.lmodal.hide_modal();

        },

        /**
         *  list => select user
         */
        list_select( item_id, indirect = false ) {

            // check if is indirect 
            // => user clicks on the element instead of the action button
            if( indirect === true && this.items[item_id].link && typeof this.items[item_id].link === 'string' ) {
                // hide list
                this.list_hide();
                // follow link instead
                this.$router.push(this.items[item_id].link);
                // stop here
                return false;
            }

            // set loading state
            this.action_loading[item_id] = true;
            this.action_loading = {...{}, ...this.action_loading};

            // trigger selected
            this.$emit('selected', {data: this.items[item_id].data, success: (data = {}) => {
                // check if data is of type Object and is not NULL
                if( typeof data === 'object' && data !== null ) {
                    // merge objects
                    this.items[item_id] = this.list_helper_merge(this.items[item_id], data);
                    // refresh items
                    this.$forceUpdate();
                }
                // reset loading state
                this.action_loading[item_id] = false;
                this.action_loading = {...{}, ...this.action_loading};
            }, error: (error) => {
                // do seomthing with the error
                // reset loading state
                this.action_loading[item_id] = false;
                this.action_loading = {...{}, ...this.action_loading};
            }});

        },

        /**
         *  list => create
         */
        list_create() {

            // set loading state
            this.creation_loading = true;

            // trigger create
            this.$emit('create', {data: this.search.term, success: (data = {}) => {
                // trigger search
                this.$emit('search', this.search.term);
                // reset loading state
                this.creation_loading = false;
            }, error: (error) => {
                // do seomthing with the error
                // reset loading state
                this.creation_loading = false;
            }});

        },

        /**
         *  list => navigate
         */
        list_navigate( event ) {

            // set items array
            let _items_array = Object.keys(this.items);
            let _items_count = (_items_array.length - 1);

            // catch arrow keys for list navigation
            if( event.keyCode === 40 || event.keyCode === 38 ) {

                // down key
                if (event.keyCode === 40) {
                    // check if max reached
                    if( this.list_current === false || this.list_current >= _items_count) {
                        // set first
                        this.list_current = 0;
                        this.list_current_id = _items_array[this.list_current];
                    } else {
                        // set next
                        this.list_current += 1;
                        this.list_current_id = _items_array[this.list_current];
                    }
                // up key
                } else if (event.keyCode === 38) {
                    // check if min reached
                    if( this.list_current === 0) {
                        // set first
                        this.list_current = _items_count;
                        this.list_current_id = _items_array[this.list_current];
                    } else {
                        // set previews
                        this.list_current -= 1;
                        this.list_current_id = _items_array[this.list_current];
                    }
                }

                // prevent default
                event.preventDefault();
                // when arrow keys were pressed, do nothing
                return false;
                
            }

        },

        /**
         *  list => navigate hover
         */
        list_navigate_hover( item_id ) {

            // set items array
            let _items_array = Object.keys(this.items);

            // set current item and current id as active
            this.list_current = _items_array.indexOf(item_id);
            this.list_current_id = item_id;

        },

        /**
         *  list => search
         */
        list_search( event ) {

            // catch arrow keys for list navigation
            if( event.keyCode > 36 && event.keyCode < 41 ) {
                // when arrow keys were pressed, do nothing
                return false;
            }

            // catch ENTER
            if( event.keyCode === 13 ) {
                // check if we have that item
                if( this.list_current_id !== false && this.items[this.list_current_id] ) {
                    // submit item
                    this.list_select(this.list_current_id);
                }
                // when ENTER pressed, do nothing
                return false;
            }

            // remove timeout
            clearTimeout(this.search_timeout);

            // set search timeout
            this.search_timeout = setTimeout(() => {

                // reset navigation ids
                this.list_current = false;
                this.list_current_id = false;

                // fire search event
                this.$emit('search', event.target.value);

            }, this.search_delay);

        },

        /**
         *  list => merge helper
         */
        list_helper_merge(target, source) {

            let isObject = (item) => {
                return (item && typeof item === 'object' && !Array.isArray(item));
            }

            let output = Object.assign({}, target);

            if (isObject(target) && isObject(source)) {
                Object.keys(source).forEach(key => {
                    if (isObject(source[key])) {
                        if (!(key in target))
                        Object.assign(output, { [key]: source[key] });
                        else
                        output[key] = this.list_helper_merge(target[key], source[key]);
                    } else {
                        Object.assign(output, { [key]: source[key] });
                    }
                });
            }

            // return merged results
            return output;

        }

	},


    /** ----------------------------------------------------
     * View => Registred components
     ----------------------------------------------------- */
     components: {
        'modal': Modal,
    },

}
</script>