import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
    
    canvas: {},
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set active product
    CANVAS_SET( state, data ) { state.feedback_comments = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get API key
	 * @param {*} context 
	 * @param {object} query 
	 */
	canvas_load( context, {canvas_view, canvas_query} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.query('product/' + context.getters.product_active_id + '/canvas/' + canvas_view, canvas_query, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

    /**
	 * Create a webhook
	 * @param {*} context 
	 * @param {object} query 
	 */
	canvas_send( context, canvas_data ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/canvas', canvas_data, function( data ) {

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
 const getters = {

	// get feedback comments
	canvas(state) {
		return state.canvas;
    },
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};