import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
	integrations: false
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Add integrations
	INTEGRATIONS_ADD( state, integrations ) { state.integrations = integrations; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Load integrations
	 * @param {*} context 
	 */
	integrations_load( context ) {

        // call api to get integrations
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.get('product/' + context.getters.product_active_id, 'integrations', function( data ) {

                // set data
				context.commit('INTEGRATIONS_ADD', data); 

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

	},

	/**
	 * Activate integration (with data)
	 * @param {*} context 
	 */
	integration_activate( context, {integration, integration_data} ) {

        // call api to get integrations
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.post('product/' + context.getters.product_active_id + '/integration/' + integration, integration_data, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

	},

	/**
	 * Deactivate integration
	 * @param {*} context 
	 */
	integration_deactivate( context, integration ) {

        // call api to get integrations
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.delete('product/' + context.getters.product_active_id + '/integration/' + integration, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

	},

	/**
	 * Update integration data
	 * @param {*} context 
	 */
	integration_update( context, {integration, integration_data} ) {

        // call api to get integrations
		return new Promise( function(resolve, reject) { 

            // query data
            ApiService.update('product/' + context.getters.product_active_id + '/integration', integration, integration_data, function( data ) {

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

	},

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get integrations
	integrations(state) {
		return state.integrations;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};