<template>
    <div class="dashboard-alert" :class="{empty: Object.keys(messages).length < 1}">

        <!-- safe_mode -->
        <div class="alert expandable important" v-if="messages.safe_mode" :class="{ active: expanded.safe_mode }" v-on:click="expanded.safe_mode = !expanded.safe_mode">
            <b>Safety Limits (Very High Traffic)</b>
            <span>To maintain the best performance for your website vistors, the widget has been set to safe mode. In-app popups and other automated features will be delayed, until you go below the safety limits threshold. <a href="https://help.sleekplan.com/en/" target="_blank">Learn more about safety limits.</a></span>
        </div>

        <!-- usage_limit_email -->
        <div class="alert expandable error" v-if="messages.usage_limit_email" :class="{ active: expanded.usage_limit_email }" v-on:click="expanded.usage_limit_email = !expanded.usage_limit_email">
            <b>Email delivery paused</b>
            <span>Your email credit limit has been exceeded; go to Settings > Subscription to adjust your limit.</span>
        </div>

        <!-- usage_limit_popup -->
        <div class="alert expandable error" v-if="messages.usage_limit_popup" :class="{ active: expanded.usage_limit_popup }" v-on:click="expanded.usage_limit_popup = !expanded.usage_limit_popup">
            <b>Popup delivery paused</b>
            <span>Your popup limit has been exceeded; go to Settings > Subscription to adjust your limit.</span>
        </div>

        <!-- usage_limit_announcement -->
        <div class="alert expandable error" v-if="messages.usage_limit_announcement" :class="{ active: expanded.usage_limit_announcement }" v-on:click="expanded.usage_limit_announcement = !expanded.usage_limit_announcement">
            <b>Announcements paused</b>
            <span>Your announcements limit has been exceeded; go to Settings > Subscription to adjust your limit.</span>
        </div>

    </div>
</template>

<script>
export default {

    name: 'message',


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
        return {
            messages: ((window.sleek_messages) ? window.sleek_messages : {}),
            expanded: {
                safe_mode: false,
                usage_limit_email: false,
                usage_limit_popup: false,
                usage_limit_announcement: false,
            }
        };

    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        product_data() {
            return this.$store.getters.product_data;
        },

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Load messages
         */
        load_messages() {

            // get product import requests from api
			this.$store.dispatch( 'wapi_get', {resource: 'product/' + this.product_data.ID, slug: 'messages'} ).then((data) => {
                // set messages
                this.messages = data;
                // set messages to window
                window.sleek_messages = data;
            });

        },

    },


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted() {
        // load active imports
        this.load_messages();
    },


}
</script>