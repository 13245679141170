const messages = {

    sidebar: {
        dashboard: 'Dashboard',
        feedback: 'Feedback',
        roadmap: 'Roadmap',
        changelog: 'Changelog',
        satisfaction: 'Satisfaction',
        promoter: 'NPS',
        survey: 'Surveys',
        user: 'Contacts',
        add_product: 'New Product / Workspace',
        account: 'Account',
        help: 'Support & Help',
        docs: 'Documentation',
        send_feedback: 'Feedback',
        logout: 'Sign out',
        moderation: 'Moderation',
        settings: 'Settings',
        settings_general: 'General',
        settings_widget: 'Widget',
        settings_page: 'Website',
        settings_privacy: 'Privacy',
        settings_email: 'Email',
        settings_popup: 'Popups',
        settings_status: 'Statuses',
        settings_type: 'Categories',
        settings_tags: 'Tags',
        settings_segments: 'Segmentation',
        settings_developer: 'Developer',
        settings_integrations: 'Integrations',
        settings_import: 'Import/Export',
        settings_plan: 'Subscription',
        organization: 'Organization',
        organization_products: 'Products',
        organization_team: 'Team',
        organization_plan: 'Subscription',
    },

    errors: {
        plan: 'Your current plan does not support this feature',
    }

}

export default messages;