/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

    /**
	 * Load initial analytics
	 * @param {*} context 
	 */
	analytics_load( context ) {

        // we need to wait for required vars to be ready
        var wait = setInterval(() => {

            // check if we are missing this vars
            if( 
                context.getters.support // null and undefined check
                && Object.keys(context.getters.support).length === 0
                && Object.getPrototypeOf(context.getters.support) === Object.prototype 
            ) {
                // return and try again
                return;
            }

            // otherwise stop interval and procceed initialising
            clearInterval(wait);

            // do not try to identify users that arent loggedin yet
            if(!context.getters.support.user_id) {
                return;
            }

            /** 
             * #1 Dataplane
             */
            try {
                // time this out so dataplane has more time to load 
                setTimeout(() => {
                    window.posthog.identify(context.getters.support.user_id, {
                        name: context.getters.support.name,
                        email: context.getters.support.email,
                        user_id: context.getters.support.user_id,
                        user_hash: context.getters.support.user_hash,
                        created_at: context.getters.support.created,
                        plan: context.getters.support.user_plan,
                        trial: context.getters.support.user_trial,
                        products_count: context.getters.support.product_count,
                        product_owner: context.getters.support.product_owner,
                    });
                }, 500);
            } catch (error) {}  

            /** 
             * #2 Google Analytics
             */
            try {
                window.gtag('config', 'G-DECHFVBJK4', {
                    'user_id': context.getters.support.user_id
                });
                window.gtag('set', 'user_properties', { 
                    'customer_id' : context.getters.support.user_id,
                    'plan': context.getters.support.user_plan,
                    'trial': context.getters.support.user_trial,
                    'owner': context.getters.support.product_owner,
                });
            } catch (error) {}  

        }, 300);
        
    },
	
	/**
	 * Fire event for analytics
	 * @param {*} context 
     * @usage this.$store.dispatch( 'analytics_event', {event: 'event-name', data: Object} );
	 */
	analytics_event( context, {event, data} ) {

        // we con not track without event name
        if( ! event ) {
            return false;
        }

        // we con not track non object data
        if( ! data || typeof data !== "object" ) {
            data = {};
        }

        // 1. -- Dataplane
        try {
            window.posthog.capture(event, data);
        } catch (error) {}

        // 2. -- Google
        try {
            window.gtag('event', event.toLowerCase().replaceAll(' ', '_'), {});
        } catch (error) {}

        // 3. -- Google Ads
        try {
            if(event == 'Sign Up') gtag('event', 'conversion', {'send_to': 'AW-10882548138/NCR3CLvElLMDEKqDmsUo'});
            if(event == 'Plan Create') gtag('event', 'conversion', {'send_to': 'AW-10882548138/Ue0xCKiJlrMDEKqDmsUo','value': ((data && data.plan) ? ((data.plan.includes('basic_')) ? 60.0 : 180.0 ) : 1.0),'currency': 'EUR','transaction_id': ''});
        } catch (error) {}

    },

    /**
	 * Fire pageview for analytics
	 * @param {*} context 
     * @usage this.$store.dispatch( 'analytics_view' );
	 */
	analytics_view( context ) {

        // trigger pageview
        window.posthog.capture('$pageview');

    },

    /**
	 * Reset analytics session
	 * @param {*} context 
     * @usage this.$store.dispatch( 'analytics_reset' );
	 */
	analytics_reset( context ) {

        // reset user session
        window.posthog.reset();

    }

};



/***********************
 * Export
***********************/

export default {
	actions
};