import ApiService from "@/common/general-api";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    product_templates: false,
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Set page data
    PRODUCT_TEMPLATES_SET( state, data ) { state.product_templates = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Get template
	 * @param {*} context 
	 */
	product_templates_get( context ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.get('product/' + context.getters.product_active_id, 'templates', function( data ) {

				// set data
				context.commit('PRODUCT_TEMPLATES_SET', data); 

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Create template
	 * @param {*} context 
	 * @param {object} page_data 
	 */
	product_templates_create( context, {type_id, template} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.post('product/' + context.getters.product_active_id + '/template/' + type_id, template, function( data ) {

				// load new templates
                context.dispatch( 'product_templates_get' ).then(() => { 
					// set success
					return resolve(data);
				})
				.catch(e => {
					// set success
					return resolve(data);
				});

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    },

	/**
	 * Delete template
	 * @param {*} context 
	 * @param {object} page_data 
	 */
	product_templates_delete( context, {type_id} ) {

        // call api to create user
		return new Promise( function(resolve, reject) { 

			// load data via API
			ApiService.delete('product/' + context.getters.product_active_id + '/template/' + type_id, function( data ) {

				// load new templates
                context.dispatch( 'product_templates_get' ).then(() => { 
					// set success
					return resolve(data);
				})
				.catch(e => {
					// set success
					return resolve(data);
				});

				// set success
				return resolve(data);

			}, function( data ) {

				// set rejection
				return reject(data);

			});

		});

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// product page data
	product_templates(state) {
		return state.product_templates;
    }

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};