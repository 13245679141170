<template>
    <div class="feedback-items" :class="{tiny: tiny == true}">
        <div class="feedback-item" v-for="feedback_id in results" v-if="feedback_items[feedback_id]" :data-item="((item_settings.data_attribut === true) ? feedback_id : false)" :class="{active: preview_id == feedback_id}">
            <router-link :to="{path: ((preview_enabled !== true) ? '/feedback/' + feedback_items[feedback_id].feedback_id : $route.fullPath)}" v-on:click.native="open( feedback_id );">
                <div class="item-inner">
                    <div class="item-right">
                        <div class="item-info">
                            <div class="info-box" v-if="feedback_items[feedback_id].estimated"> 
                                <span>{{feedback_items[feedback_id].estimated | estimated}}</span>
                            </div>
                            <div class="info-box" v-if="feedback_items[feedback_id].total_comments > 0 && item_settings.comments == true"> 
                                <span>{{feedback_items[feedback_id].total_comments | number(1)}}</span>
                            </div>
                            <!--<div class="info-box">
                                <span>MG</span>
                            </div>-->
                            <popper
                                :ref="'popper_precedence_' + feedback_id"
                                trigger="click"
                                :stop-propagation="true"
                                :append-to-body="true"
                                :options="{
                                    placement: 'bottom-end',
                                    modifiers: { offset: { offset: '0,5px' } } 
                                }">

                                <div class="popper menu dropdown-menu">
                                    <div class="popper-inner">
                                        <div class="popper-menu">
                                            <div class="popper-menu">
                                                <div class="popper-menu-title">
                                                    Precedence
                                                </div>
                                                <div class="popper-menu-item" v-for="index in 4" :key="index">
                                                    <a href="#_" v-on:click.prevent="update_precedence(feedback_id, (index - 1));">
                                                        <div class="popper-menu-inner">
                                                            <div class="popper-menu-icon">
                                                                <ion-icon name="ios-checkmark" v-if="feedback_items[feedback_id].effort == (index - 1)"></ion-icon>
                                                            </div>
                                                            <div class="popper-menu-text" v-if="(index - 1) == 0">Later</div>
                                                            <div class="popper-menu-text" v-if="(index - 1) == 1">Normal</div>
                                                            <div class="popper-menu-text" v-if="(index - 1) == 2">Medium</div>
                                                            <div class="popper-menu-text" v-if="(index - 1) == 3">High</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-box" slot="reference" v-on:click.prevent="function(){}"> 
                                    <span class="precedence-tag" :class="'precedence-' + feedback_items[feedback_id].effort" v-if="typeof feedback_items[feedback_id].effort === 'number' && item_settings.precedence == true">
                                        <svg v-if="feedback_items[feedback_id].effort == 0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><title>Layer 1</title><line stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" id="svg_1" y2="256" x2="411.00002" y1="256" x1="101" stroke-width="48" fill="none"/></g></svg>
                                        <svg v-if="feedback_items[feedback_id].effort == 1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><title>Layer 1</title><path id="svg_1" d="m112,328l144,-144l144,144" stroke-width="48" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" fill="none"/></g></svg>
                                        <svg v-if="feedback_items[feedback_id].effort == 2" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><title>Layer 1</title><path id="svg_1" d="m111,278l144,-144l144,144" stroke-width="48" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" fill="none"/><path id="svg_2" d="m112,381l144,-144l144,144" stroke-width="48" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" fill="none"/></g></svg>
                                        <svg v-if="feedback_items[feedback_id].effort == 3" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><title>Layer 1</title><path id="svg_1" d="m110,231l144,-144l144,144" stroke-width="48" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" fill="none"/><path id="svg_2" d="m111,334l144,-144l144,144" stroke-width="48" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" fill="none"/><path id="svg_3" d="m112,440l144,-144l144,144" stroke-width="48" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" fill="none"/></g></svg>
                                    </span>
                                </div>
                            </popper>
                            <popper
                                :ref="'popper_type_' + feedback_id"
                                trigger="click"
                                :stop-propagation="true"
                                :append-to-body="true"
                                :options="{
                                    placement: 'bottom-end',
                                    modifiers: { offset: { offset: '0,5px' } } 
                                }">

                                <div class="popper menu dropdown-menu">
                                    <div class="popper-inner">
                                        <div class="popper-menu max-height">
                                            <div class="popper-menu">
                                                <div class="popper-menu-title">
                                                    Category
                                                </div>
                                                <div class="popper-menu-item" v-for="type in Object.values(product_settings.type).filter(type => type.disable_feedback !== true).sort((a, b) => { return a.order - b.order; })">
                                                    <a href="#_" v-on:click.prevent="update_type(feedback_id, type.key);">
                                                        <div class="popper-menu-inner">
                                                            <div class="popper-menu-icon">
                                                                <ion-icon name="ios-checkmark" v-if="feedback_items[feedback_id].type == type.key"></ion-icon>
                                                                <div class="icon-placeholder" v-else :style="{'background-color': type.color}"></div>
                                                            </div>
                                                            <div class="popper-menu-text">{{type.name}}</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-box" slot="reference" v-on:click.prevent="function(){}"> 
                                    <span class="item-tag more" v-if="product_settings.type[feedback_items[feedback_id].type] && item_settings.type == true" :style="{background: product_settings.type[feedback_items[feedback_id].type].color}">
                                        <span class="tag-icon">
                                            <span>{{product_settings.type[feedback_items[feedback_id].type].name}}</span>
                                            <svg v-if="product_settings.type[feedback_items[feedback_id].type].hidden === true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 448a15.92 15.92 0 01-11.31-4.69l-352-352a16 16 0 0122.62-22.62l352 352A16 16 0 01432 448zM255.66 384c-41.49 0-81.5-12.28-118.92-36.5-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 00.14-2.94L93.5 161.38a2 2 0 00-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 00-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.13 239.13 0 0075.8-12.58 2 2 0 00.77-3.31l-21.58-21.58a4 4 0 00-3.83-1 204.8 204.8 0 01-51.16 6.47zM490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.34 227.34 0 00-74.89 12.83 2 2 0 00-.75 3.31l21.55 21.55a4 4 0 003.88 1 192.82 192.82 0 0150.21-6.69c40.69 0 80.58 12.43 118.55 37 34.71 22.4 65.74 53.88 89.76 91a.13.13 0 010 .16 310.72 310.72 0 01-64.12 72.73 2 2 0 00-.15 2.95l19.9 19.89a2 2 0 002.7.13 343.49 343.49 0 0068.64-78.48 32.2 32.2 0 00-.1-34.78z"/><path d="M256 160a95.88 95.88 0 00-21.37 2.4 2 2 0 00-1 3.38l112.59 112.56a2 2 0 003.38-1A96 96 0 00256 160zM165.78 233.66a2 2 0 00-3.38 1 96 96 0 00115 115 2 2 0 001-3.38z"/></svg>
                                            <svg v-if="product_settings.type[feedback_items[feedback_id].type].segment" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Pie Chart</title><path d="M256.05 80.65Q263.94 80 272 80c106 0 192 86 192 192s-86 192-192 192A192.09 192.09 0 0189.12 330.65" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"/><path d="M256 48C141.12 48 48 141.12 48 256a207.29 207.29 0 0018.09 85L256 256z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                                        </span>
                                    </span>
                                    <span class="item-tag more" v-else-if="item_settings.type == true">
                                        N/A
                                    </span>
                                </div>
                            </popper>
                            <popper
                                :ref="'popper_status_' + feedback_id"
                                trigger="click"
                                :stop-propagation="true"
                                :append-to-body="true"
                                :options="{
                                    placement: 'bottom-end',
                                    modifiers: { offset: { offset: '0,5px' } } 
                                }">

                                <div class="popper menu dropdown-menu">
                                    <div class="popper-inner">
                                        <div class="popper-menu max-height">
                                            <div class="popper-menu">
                                                <div class="popper-menu-title">
                                                    Status
                                                </div>
                                                <div class="popper-menu-item" v-for="status in Object.values(product_settings.status).sort((a, b) => { return a.order - b.order; })">
                                                    <a href="#_" v-on:click.prevent="update_status(feedback_id, status.key);">
                                                        <div class="popper-menu-inner">
                                                            <div class="popper-menu-icon">
                                                                <ion-icon name="ios-checkmark" v-if="feedback_items[feedback_id].status == status.key"></ion-icon>
                                                                <div class="icon-placeholder" v-else :style="{'background-color': status.color}"></div>
                                                            </div>
                                                            <div class="popper-menu-text">{{status.name}}</div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-box" slot="reference" v-on:click.prevent="function(){}"> 
                                    <span class="item-tag more" v-if="product_settings.status[feedback_items[feedback_id].status] && item_settings.status == true" :style="{background: product_settings.status[feedback_items[feedback_id].status].color}">
                                        {{product_settings.status[feedback_items[feedback_id].status].name}}
                                    </span>
                                    <span class="item-tag more" v-else-if="item_settings.status == true">
                                        N/A
                                    </span>
                                </div>
                            </popper>
                        </div>
                        <div class="item-meta">
                            <div>
                                <time :datetime="feedback_items[feedback_id].created">{{ feedback_items[feedback_id].created | moment }}</time>
                            </div>
                            <div>
                                <router-link class="user-info" :to="{path: '/user/' + feedback_items[feedback_id].user.user_id}">
                                    <div class="user-by">
                                        by
                                    </div>
                                    <div class="user-img">
                                        <img :src="feedback_items[feedback_id].user.data_img">
                                    </div>
                                    <div class="user-name">
                                        <span>{{feedback_items[feedback_id].user.data_full_name || feedback_items[feedback_id].user.data_name}}</span>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="item-vote vote-box" v-if="item_settings.impact === false && feedback_items[feedback_id].is_active == true">
                        <div class="vote-inner" :class="{active: feedback_items[feedback_id].can_vote}">
                            <div class="vote-button plus" :class="{active: feedback_items[feedback_id].vote == 1}" v-on:click.stop.prevent="((feedback_items[feedback_id].can_vote) ? vote(feedback_id, 1) : null)">
                                <ion-icon name="ios-arrow-up" role="img" class="md hydrated" aria-label="arrow round up"></ion-icon>
                            </div>
                            <div class="vote-count">{{feedback_items[feedback_id].total_ratio | number(1)}}</div>
                            <div class="vote-button minus" :class="{active: feedback_items[feedback_id].vote == -1}" v-on:click.stop.prevent="((feedback_items[feedback_id].can_vote) ? vote(feedback_id, -1) : null)" v-if="product_settings.general.downvote === true">
                                <ion-icon name="ios-arrow-down" role="img" class="md hydrated" aria-label="arrow round up"></ion-icon>
                            </div>
                        </div>
                    </div>
                    <div class="item-approval" v-else-if="feedback_items[feedback_id].is_active == false">
                        <div v-if="tiny !== true">
                            <div v-on:click.stop.prevent="moderate(feedback_id, true)">
                                <ion-icon class="icon" name="checkmark"></ion-icon>
                            </div>
                            <modal ref="rejection" :append_body="true">
                                <h3 slot="header">Leave a message</h3>
                                <div slot="content">
                                    <div>
                                        <div class="form-input">
                                            <label>Rejection Message:</label>
                                            <textarea class="text-general" placeholder="You have the option to leave an email notification for the author." v-model="message_rejection"></textarea>
                                        </div>
                                        <button type="button" class="btn danger" v-on:click.stop.prevent="moderate(feedback_id, false)" autofocus>
                                            <span v-if="moderation_loading !== true"> Delete post </span>
                                            <img v-else class="loading" src="@/assets/media/svg/loading.svg">
                                        </button>
                                    </div>
                                </div>

                                <div slot="reference">
                                    <ion-icon class="icon" name="close"></ion-icon>
                                </div>
                            </modal>
                            <div class="loading" v-if="moderation_loading === true">
                                <img class="loading" src="@/assets/media/svg/loading.svg">
                            </div>
                        </div>
                    </div>
                    <div class="item-scoring" v-else>
                        <div class="item-scoring-inner">
                            <div>
                                {{feedback_items[feedback_id].scoring | round | number(1)}}
                            </div>
                        </div>
                    </div>
                    <div class="item-data">
                        <div class="item-title">
                            <div style="background-color: rgb(139, 195, 74); background-position: initial initial; background-repeat: initial initial;width: 8px;height: 8px;border-radius: 50%;position: absolute;left: -15px;top: 8.5px;" v-if="feedback_items[feedback_id].status !== 'open' && product_settings.status[feedback_items[feedback_id].status] && item_settings.status == true" :style="{background: product_settings.status[feedback_items[feedback_id].status].color}"></div>
                            {{feedback_items[feedback_id].title}}
                        </div>
                        <span class="item-desc">{{feedback_items[feedback_id].description}}</span>
                        <div class="item-overlay"></div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import Popper from 'vue-popperjs';
import moment from 'moment';
import Modal from '@/components/module.loc.modal';
import NumberHelper from '@/common/helper-number.js';

export default {

    name: 'feedbackitem',

    props: ['results', 'settings', 'preview', 'tiny'],


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        return {
            // generals
            tiny: ((this.tiny !== undefined) ? true : false ),
            item_settings: Object.assign({type: true, comments: true, status: true, impact: false, precedence: true}, this.settings),
            preview_enabled: ((this.preview !== undefined) ? this.preview : (( (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 1200 ) ? true : false )),
            moderation_loading: false,

            // messages
            message_rejection: '',
        }
    },


    /** ----------------------------------------------------
     * View => Dynamical access to data
     ----------------------------------------------------- */
    computed: {

        product_settings() {
            return this.$store.getters.product_settings;
        },

        feedback_items() {
            return this.$store.getters.feedback_items;
        },

        preview_id() {
            return ((this.$route.params) ? this.$route.params.id : false);
        }
        
    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  Update status
         */
        update_status( feedback_id, status ) {

            // close popper
            var lol = this.$refs['popper_status_' + feedback_id][0].doClose();

            // set new status
            this.$store.dispatch( 'feedback_item_update', {feedback_id: feedback_id, feedback_data: {status: status}} )
            .then((data) => {
                // clear & refresh results containing status
                this.$store.dispatch( 'feedback_query_clear', {key: 'filter', value: status} );
            })
            .catch(e => {
				this.$store.dispatch( 'notification_add', { name: 'update_error', type: 'error', message: e.message } );
            });
            
        },

        /**
         *  Update type
         */
        update_type( feedback_id, type ) {

            // close popper
            var lol = this.$refs['popper_type_' + feedback_id][0].doClose();

            // set new status
            this.$store.dispatch( 'feedback_item_update', {feedback_id: feedback_id, feedback_data: {type: type}} )
            .then((data) => {
                // clear & refresh results containing type
                this.$store.dispatch( 'feedback_query_clear', {key: 'type', value: type} );
            })
            .catch(e => {
				this.$store.dispatch( 'notification_add', { name: 'update_error', type: 'error', message: e.message } );
            });
            
        },

        /**
         *  Update precedence
         */
        update_precedence( feedback_id, precedence ) {

            // close popper
            var lol = this.$refs['popper_precedence_' + feedback_id][0].doClose();

            // set new status
            this.$store.dispatch( 'feedback_item_update', {feedback_id: feedback_id, feedback_data: {effort: precedence}} )
            .then((data) => {})
            .catch(e => {
				this.$store.dispatch( 'notification_add', { name: 'update_error', type: 'error', message: e.message } );
            });
            
        },

        /**
         *  Update vote
         */
        vote( feedback_id, vote ) {

            // set vote
            this.$store.dispatch( 'feedback_vote', {feedback_id: feedback_id, vote: vote} )
            .then((data) => {})
            .catch(e => {
				this.$store.dispatch( 'notification_add', { name: 'vote_error', type: 'error', message: e.message } );
            });

        },

        /**
         *  Moderate
         */
        moderate( feedback_id, approve ) {

            // set loading status
            this.moderation_loading = true;

            // if approve
            if( approve === true ) {

                // set active
                this.$store.dispatch( 'feedback_item_update', {feedback_id: feedback_id, feedback_data: {is_active: true}} )
                .then((data) => {
                    // set loading status
                    this.moderation_loading = false;
                })
                .catch(e => {
                    // error
                    this.$store.dispatch( 'notification_add', { name: 'approval_error', type: 'error', message: e.message } );
                    // set loading status
                    this.moderation_loading = false;
                });

            }

            // if not approve
            if( approve === false ) {

                // delete item
                this.$store.dispatch( 'feedback_item_delete', {feedback_id: feedback_id, message: (( this.message_rejection ) ? this.message_rejection : false)} )
                .then((data) => {
                    // set loading status
                    this.moderation_loading = false;
                    // reset rejection message
                    this.message_rejection = '';
                    // close preview
                    this.open(false);
                })
                .catch(e => {
                    // error
                    this.$store.dispatch( 'notification_add', { name: 'approval_error', type: 'error', message: e.message } );
                    // set loading status
                    this.moderation_loading = false;
                });

            }

        },

        /**
         *  Load preview
         */
        open( feedback_id ) {

            // check if preview is enabled
            if( this.preview_enabled !== true ) {
                return false;
            }

            // trigger open on parent
            this.$emit('open', feedback_id);

        }

	},


    /** ----------------------------------------------------
     * View => Registred components
     ----------------------------------------------------- */
    components: {
        'popper': Popper,
        'modal': Modal,
    },


    /** ----------------------------------------------------
     * Filter
     ----------------------------------------------------- */
    filters: {
        round: function (scoring) {
            return Math.round(scoring * 10) / 10;
        },
        moment: function (date) {
            return moment.utc(date).fromNow();
        },
        estimated: function (date) {
            return moment.utc(date).format('MMM, YY');
        },
        number: function (number, digits) {
            return NumberHelper.format(number, digits);
        }
    }

}
</script>
