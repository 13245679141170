import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
 const state = {
	app_state: JwtService.getData('state')
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {
	
	// Add notification
	APP_STATE_UPDATE( state, app_state ) { state.app_state = app_state; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {
	
	/**
	 * Update state
	 * @param {*} context 
	 * @param {object} notification_data 
	 */
	app_state_update( context, {state_key, state_data} ) {

        // load current state
        var app_state = Object.assign({}, state.app_state, {[state_key]: state_data});

		// save state
		JwtService.setData(app_state, 'state');

        // commit changes
        context.commit('APP_STATE_UPDATE', app_state);

	},
	
	/**
	 * Delete state
	 * @param {*} context 
	 * @param {object} notification_data 
	 */
	app_state_delete( context, state_key ) {

        // load current state
        var app_state = Object.assign({}, state.app_state );

		// delete key
		delete app_state[state_key];

		// save state
		JwtService.setData(app_state, 'state');

        // commit changes
        context.commit('APP_STATE_UPDATE', app_state);

    }

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get noticiations
	app_state(state) {
		return state.app_state;
	},

};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};