import Vue from 'vue'
import Router from 'vue-router'
import home from './views/home.vue'

// load Router
Vue.use(Router)

// Define Routes
export default new Router({
    linkExactActiveClass: 'nav-item-active',
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: home
        },
        {
            path: '/activites',
            name: 'activites',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/activites.vue')
        },
        {
            path: '/feedback',
            name: 'feedback',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/feedback.vue')
        },
        {
            path: '/feedback/add',
            name: 'feedback-add',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/feedback-add.vue')
        },
        {
            path: '/feedback/edit/:id',
            name: 'feedback-edit',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/feedback-add.vue')
        },
        {
            path: '/feedback/:id',
            name: 'feedback-item',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/feedback.vue')
        },
        {
            path: '/roadmap',
            name: 'roadmap',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/roadmap.vue')
        },
        {
            path: '/changelog',
            name: 'changelog',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/changelog.vue')
        },
        {
            path: '/changelog/subscriber',
            name: 'changelog-subscriber',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/changelog-subscriber.vue')
        },
        {
            path: '/changelog/add',
            name: 'changelog-add',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/changelog-add.vue')
        },
        {
            path: '/changelog/edit/:id',
            name: 'changelog-edit',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/changelog-add.vue')
        },
        {
            path: '/changelog/:id',
            name: 'changelog-item',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/changelog-item.vue')
        },
        {
            path: '/satisfaction',
            name: 'satisfaction',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/satisfaction.vue')
        },
        {
            path: '/satisfaction/add',
            name: 'satisfaction-add',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/satisfaction-add.vue')
        },
        {
            path: '/satisfaction/:id',
            name: 'satisfaction-item',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/satisfaction-item.vue')
        },
        {
            path: '/promoter',
            name: 'promoter',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/promoter.vue')
        },
        {
            path: '/promoter/feed',
            name: 'promoter-feed',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/promoter-feed.vue')
        },
        {
            path: '/promoter/add',
            name: 'promoter-add',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/promoter-add.vue')
        },
        {
            path: '/survey',
            name: 'survey',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/survey.vue')
        },
        {
            path: '/survey/add',
            name: 'survey-add',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/survey-add.vue')
        },
        {
            path: '/survey/edit/:id',
            name: 'survey-add',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/survey-add.vue')
        },
        {
            path: '/survey/:id',
            name: 'survey-item',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/survey-item.vue')
        },
        {
            path: '/survey/:id/:page',
            name: 'survey-item',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/survey-item.vue')
        },
        {
            path: '/moderation',
            name: 'moderation',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/moderation.vue')
        },
        {
            path: '/analytics/feed',
            name: 'analytics-feed',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/analytics-feed.vue')
        },
        {
            path: '/settings',
            name: 'settings',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings.vue')
        },
        {
            path: '/settings/general',
            name: 'settings-general',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-general.vue')
        },
        {
            path: '/settings/general/:page',
            name: 'settings-general',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-general.vue')
        },
        {
            path: '/settings/connections',
            name: 'settings-connections',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-connections.vue')
        },
        {
            path: '/settings/identity-manager',
            name: 'settings-identity-manager',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-identity-manager.vue')
        },
        {
            path: '/settings/widget',
            name: 'settings-widget',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-widget.vue')
        },
        {
            path: '/settings/widget/:page',
            name: 'settings-widget',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-widget.vue')
        },
        {
            path: '/settings/privacy',
            name: 'settings-privacy',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-privacy.vue')
        },
        {
            path: '/settings/email',
            name: 'settings-email',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-email.vue')
        },
        {
            path: '/settings/email/template',
            name: 'settings-email-template',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-email-template.vue')
        },
        {
            path: '/settings/popup',
            name: 'settings-popup',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-popup.vue')
        },
        {
            path: '/settings/popup/add',
            name: 'settings-popup-add',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-popup-add.vue')
        },
        {
            path: '/settings/status',
            name: 'settings-status',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-status.vue')
        },
        {
            path: '/settings/type',
            name: 'settings-type',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-type.vue')
        },
        {
            path: '/settings/type/templates',
            name: 'settings-type-templates',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-type-templates.vue')
        },
        {
            path: '/settings/tags',
            name: 'settings-tags',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-tags.vue')
        },
        {
            path: '/settings/segments',
            name: 'settings-segments',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-segments.vue')
        },
        {
            path: '/settings/developer',
            name: 'settings-developer',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-developer.vue')
        },
        {
            path: '/settings/developer/logs',
            name: 'settings-developer-logs',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-developer-logs.vue')
        },
        {
            path: '/settings/integrations',
            name: 'settings-integrations',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-integrations.vue')
        },
        {
            path: '/settings/import',
            name: 'settings-import',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-import.vue')
        },
        {
            path: '/settings/import/:page',
            name: 'settings-import',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-import.vue')
        },
        {
            path: '/settings/plan',
            name: 'settings-plan',
            meta: {requiresAuth: true, sidebar: true, type: 2},
            component: () => import('./views/settings-plan.vue')
        },
        {
            path: '/settings/designer/:type',
            name: 'settings-designer',
            meta: {requiresAuth: true, sidebar: false, type: 1},
            component: () => import('./views/settings-designer.vue')
        },
        {
            path: '/settings/designer/:type/:section',
            name: 'settings-designer',
            meta: {requiresAuth: true, sidebar: false, type: 1},
            component: () => import('./views/settings-designer.vue')
        },
        {
            path: '/add',
            name: 'add',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/add.vue')
        },
        {
            path: '/account',
            name: 'account',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/account.vue')
        },
        {
            path: '/account/views',
            name: 'account-views',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/account-views.vue')
        },
        {
            path: '/user/',
            name: 'user',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/user.vue')
        },
        {
            path: '/user/:id',
            name: 'user-item',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/user-item.vue')
        },
        {
            path: '/help/',
            name: 'help',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/help.vue')
        },
        {
            path: '/onboard/',
            name: 'onboard',
            meta: {requiresAuth: true, sidebar: false, type: 1},
            component: () => import('./views/onboard.vue')
        },
        {
            path: '/onboard/:step',
            name: 'onboard-step',
            meta: {requiresAuth: true, sidebar: false, type: 1},
            component: () => import('./views/onboard.vue')
        },
        {
            path: '/organization/products',
            name: 'organization-products',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/organization-products.vue')
        },
        {
            path: '/organization/team',
            name: 'organization-team',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/organization-team.vue')
        },
        {
            path: '/organization/team/:id',
            name: 'organization-team-item',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/organization-team-item.vue')
        },
        {
            path: '/organization/connections',
            name: 'organization-connections',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/organization-connections.vue')
        },
        {
            path: '/organization/identity-manager',
            name: 'organization-identity-manager',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/organization-identity-manager.vue')
        },
        {
            path: '/organization/plan',
            name: 'organization-plan',
            meta: {requiresAuth: true, sidebar: true, type: 1},
            component: () => import('./views/organization-plan.vue')
        },
        {
            path: '/login',
            name: 'login',
            meta: {requiresAuth: false, sidebar: false, isAuth: true},
            component: () => import('./views/login.vue')
        },
        {
            path: '/sign-up',
            name: 'sign-up',
            meta: {requiresAuth: false, sidebar: false, isAuth: true},
            component: () => import('./views/signup.vue')
        },
        {
            path: '/reset',
            name: 'reset',
            meta: {requiresAuth: false, sidebar: false, isAuth: true},
            component: () => import('./views/reset.vue')
        },
        {
            path: '/confirm',
            name: 'confirm',
            meta: {requiresAuth: false, sidebar: false},
            component: () => import('./views/confirm.vue')
        },
        {
            path: '/oauth',
            name: 'oauth',
            meta: {requiresAuth: false, sidebar: false},
            component: () => import('./views/oauth.vue')
        },
        {
            path: '/connect/:service_id',
            name: 'connect',
            meta: {requiresAuth: false, sidebar: false},
            component: () => import('./views/connect.vue')
        },
        {
            path: '/unsubscribe',
            name: 'unsubscribe',
            meta: {requiresAuth: false, sidebar: false},
            component: () => import('./views/unsubscribe.vue')
        },
        {
            path: '*',
            name: '404',
            meta: {requiresAuth: false, sidebar: false},
            component: () => import('./views/error.vue')
        }
    ]
})
