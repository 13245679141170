import ApiService from "@/common/general-api";
import JwtService from "@/common/general-jwt";



/** ----------------------------------------------------
 * State => Holds all data we need
 ----------------------------------------------------- */
const state = {
    
    feedback_items: false,
	
};



/** ----------------------------------------------------
 * Mutations => Updates data in State
 ----------------------------------------------------- */
const mutations = {

    // Set active product
    FEEDBACK_SET_ITEMS( state, data ) { state.feedback_items = data; },

};



/** ----------------------------------------------------
 * Actions => Proccess and fetch data
 ----------------------------------------------------- */
const actions = {

	/**
	 * Load / refresh single feedback item
	 * @param {*} {feedback_id, force}
	 */
    feedback_item_load( context, {feedback_id, force} ) {

        // call api to get single feedback item
		return new Promise( function(resolve, reject) { 
            
            // check if item exists
            if( state.feedback_items[feedback_id] && force !== true ) {

                // set success
                return resolve( state.feedback_items[feedback_id] );

            // if feedback item doesnt exist try to load
            } else {

                // load data via API
                ApiService.get('feedback/' + context.getters.product_active_id + '/item', feedback_id, function( data ) {

                    // load new product into our product store
                    context.dispatch( 'feedback_item_add', {[feedback_id]: data} );

                    // set success
                    return resolve(data);

                }, function( data ) {

                    // set rejection
                    return reject(data);

                });
            
            }

        });
        
    },
    
    /**
	 * Create new feedback item
	 * @param {*} context 
	 */
    feedback_item_create( context, feedback_data ) {

        // call api to create feedback
		return new Promise( function(resolve, reject) { 

            // create feedback via api
            ApiService.post('feedback/' + context.getters.product_active_id + '/item/', feedback_data, function( data ) {

                // REMOVE feedback queries
                // set semaphore so we dont run twice into loading process
                context.commit('FEEDBACK_SET_QUERY', {});
                // set semaphore so we dont run twice into loading process
                context.commit('FEEDBACK_SET_SEMAPHORE', {});

                // get single product
                context.dispatch( 'feedback_item_load', {feedback_id: data.feedback_id, force: true} );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },
    
    /**
	 * Update feedback item
	 * @param {*} context 
	 */
	feedback_item_update( context, {feedback_id, feedback_data} ) {

        // call api to update feedback
		return new Promise( function(resolve, reject) { 

            // update feedback via api
            ApiService.update('feedback/' + context.getters.product_active_id, 'item/' + feedback_id, feedback_data, function( data ) {

                // get single product
                context.dispatch( 'feedback_item_load', {feedback_id: feedback_id, force: true} );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Delete feedback item
	 * @param {*} context 
	 */
    feedback_item_delete( context, {feedback_id, message = false} ) {

        // set deletion path
        var deletion_path = feedback_id;

        // check if we have a message
        if( message !== false ) {
            // add message to the path
            deletion_path = deletion_path + '?message=' + encodeURIComponent(message);
        }

        // call api to delete feedback
		return new Promise( function(resolve, reject) { 

            // delete feedback via api
            ApiService.delete('feedback/' + context.getters.product_active_id + '/item/' + deletion_path, function( data ) {

                // get single product
                context.dispatch( 'feedback_item_remove', data.feedback_id );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Merge feedback item
	 * @param {*} context 
	 */
	feedback_item_merge( context, {feedback_id, merge_id} ) {

        // call api to update feedback
		return new Promise( function(resolve, reject) { 

            // update feedback via api
            ApiService.update('feedback/' + context.getters.product_active_id, 'merge/' + feedback_id + '/' + merge_id, {}, function( data ) {

                // get single product
                context.dispatch( 'feedback_item_remove', feedback_id );

                // set success
                return resolve(data);

            }, function( data ) {

                // set rejection
                return reject(data);

            });
            
        });

    },

    /**
	 * Add feedback item(s) to store
	 * @param {*} context 
	 */
	feedback_item_add( context, items ) {

        // load current feedback items
        var feedback_items = Object.assign({}, context.getters.feedback_items);
        // add new item(s)
        Object.assign(feedback_items, items);

        // set feedback items
        context.commit('FEEDBACK_SET_ITEMS', feedback_items); 

    },

    /**
	 * Remove feedback item(s) from store
	 * @param {*} context 
	 */
	feedback_item_remove( context, feedback_id ) {

        // load current feedback items
        var feedback_items = Object.assign({}, context.getters.feedback_items);
        // remove item
        delete feedback_items[feedback_id];

        // set feedback items
        context.commit('FEEDBACK_SET_ITEMS', feedback_items); 

    },

};



/** ----------------------------------------------------
 * Getters => Access data from outside
 ----------------------------------------------------- */
const getters = {

	// get feedback item list
	feedback_items(state) {
		return state.feedback_items;
	},
	
};



/***********************
 * Export
***********************/

export default {
	state,
	getters,
	actions,
	mutations
};