<template>
    <h2 class="title breadcrumbs">
        <router-link to="/">Home</router-link> 
        <span v-for="item in items">
            <ion-icon name="ios-arrow-forward"></ion-icon>
            <router-link v-if="item.url" :to="item.url">{{item.name}}</router-link>
            <span v-else>{{item.name}}</span>
        </span>
    </h2>
</template>

<script>
export default {

    name: 'breadcrumbs',

    props: ['items'],

}
</script>