import Vue from 'vue';
import ApiService from "@/common/general-api";
import { SOCKET_URL } from "@/common/config";

/**
 * **************************
 * General:
 * WebSocket management
 * 
 * USAGE:
 * Init     -> this.$socket.connect(() => { this.$socket.subscribe(['private-admin-product-1', 'private-admin-user-2']); });
 * Listen   -> this.$socket.on('private-admin-user-' + this.$store.getters.user_data.ID, 'notification', this.test);
 * Unlisten -> this.$socket.off('private-admin-user-' + this.$store.getters.user_data.ID, 'notification', this.test);
 * **************************
 */

const SocketService = {

    
    // defining some global variables
    server:         SOCKET_URL,
    socket:         false,
    socket_id:      false,
    socket_active:  false,
    ping_interval:  false,
    retry_limit:    10,
    retry_count:    0,
    eventHub:       new Vue(),
    channels:       [],
    channels_map:   [],

    
	/**
     * Connect to WebSocket
     * @param {*} callback
     */
    connect( callback ) {

        // dont reinitialize an active WebSocket
        if( this.socket_active !== false ) {
            // stop here
            return false;
        }

        // build new WebSocket connection
        this.socket = new WebSocket('wss://' + this.server + '?protocol=7&client=js&version=8.0.1&flash=false');

        // WebSocket connection is opened
        this.socket.onopen = (e) => {

            // WebSocket connection is closed
            this.socket.onclose = (e) => {
                // error message
                console.warn('Socket is closed: Reconnect will be attempted in 30 seconds.', e.reason);
                // stop ping on current Socket
                clearInterval(this.ping_interval);
                // set socket to be inactive
                this.socket_active = false;
                // wait 30 second and try to reconnect
                setTimeout(() => {
                    // check if retry limit is reached
                    if( this.retry_count < this.retry_limit ) {
                        // try to reconnect
                        this.connect(()=>{});
                        // update retry count
                        this.retry_count++;
                    }
                }, 30000);
            };

            // WebSocket connection throw an error
            this.socket.onerror = (err) => {
                // error message
                console.error('Socket encountered error: ', err.message, 'Closing socket');
                // close connection
                this.socket.close();
            };

            // WebSocket receives message
            this.socket.onmessage = (e) => {

                // make sure we dont run into issues while parsing
                try {
                    // parse response JSON to JS Object
                    var response = JSON.parse(e.data);
                } catch(e) {}

                // check if response contains a data string
                if( typeof response.data == 'string' ) {
                    // make sure we dont run into issues while parsing
                    try {
                        // parse response data
                        response.data = JSON.parse(response.data);
                    } catch(e) {}
                }
                
                // if connection to Server is established 
                if( response.event == 'pusher:connection_established' ) {
                    // reset retry limit
                    this.retry_count = 0;
                    // start ping pong
                    this.ping( response.data.activity_timeout );
                    // set socket to be active
                    this.socket_active = true;
                    // set socket id
                    this.socket_id = response.data.socket_id;
                    // connect channels
                    this.subscribe();
                    // return
                    return callback();
                }

                // if event is a puser speicifc event
                if( response.event.includes('pusher') ) {
                    // return
                    return false;
                }

                // trigger message
                this.message( response );
    
            };

        };

    },


    /**
     * Trigger a message
     * @param {*} interval 
     */
    message( response ) {

        // check if we have a mapped channel
        if( (response.channel) in this.channels_map ) {
            // send event (mapped channel name)
            this.eventHub.$emit(this.channels_map[response.channel] + '-' + response.event, response.data, true);
        }

        // send event (original channel name)
        this.eventHub.$emit(response.channel + '-' + response.event, response.data, true);

    },

    
    /**
     * PingPong for WebSocket timeouts
     * @param {*} interval 
     */
    ping( interval ) {

        // set interval for doing ping/pong with the WebSocket
        this.ping_interval = setInterval(() => {

            // send ping
            this.socket.send(JSON.stringify({
                event: 'pusher:ping',
                data: {}
            }));
        
        // interval in seconds based from WebSocket callback
        }, (interval * 1000));

    },


    /**
     * Subscribe to channels
     * @param {*} user_id 
     */
    subscribe( _channels = false ) {

        // if we have channels set
        if( _channels !== false ) {
            // if is only a single channel
            if( Array.isArray(_channels) === false ) {
                // convert to array
                _channels = [_channels];
            }
            // connect current channels and new channel
            var _concat     = this.channels.concat(_channels);
            // filter duplicates
            this.channels   = _concat.filter((item, pos) => _concat.indexOf(item) === pos);
        }
        
        // check if we have a channel tosubscribe
        if( this.channels.length <= 0 ) {
            // otherwise return false
            return false;
        }
        
        // PLACE FOR auth
        this.auth( ( auth_channels ) => {
            // subscribe to each channel
            auth_channels.forEach(channel => {
                // subscribe to channel
                this.socket.send(JSON.stringify({
                    event: 'pusher:subscribe',
                    data: {
                        auth: channel.auth,
                        channel: channel.name
                    }
                }));
            });
        });
        
    },


    /**
     * Map channels to friendly name
     * @param {*} _map 
     */
    map( _map ) {

        // we accept only Objects here
        if( 
            typeof _map === 'object' &&
            !Array.isArray(_map) &&
            _map !== null
        ) {
            // set channels mapping
            this.channels_map = _map;
            // success
            return true;
        }

        // failure
        return false;

    },


    /**
     * Listen to channel events
     * @param {*} channel 
     * @param {*} event 
     * @param {*} callback 
     */
    on( channel, event, callback ) {

        // load button pressed
        this.eventHub.$on(channel + '-' + event, callback);

    },


    /**
     * Listen to channel events
     * @param {*} channel 
     * @param {*} event 
     * @param {*} callback 
     */
    off( channel, event, callback ) {
        
        // load button pressed
        this.eventHub.$off(channel + '-' + event, callback);

    },


    /**
     * Authenticate subscription with socket ID
     * @param {*} callback 
     */
    auth( callback ) {

        // update feedback via api
        ApiService.post('/socket/auth', {
            socket_id: this.socket_id,
            channels: this.channels
        }, ( data ) => {
            // return array
            return callback(data);
        }, () => {
            // return empty
            return callback([]);
        });

    }
	
};

export default SocketService;